import React from 'react';
import './App.css';
import {ThemePrevSelected, ThemeSwitch, dtRowColor, frontColor, cacheColor} from './theme.js';
import {OPModePrevSelected, OPModeSwitch} from './opmode.js';
// import {DBScopePrevSelected, DBScopeSwitch} from './dbscope.js';
import {LoginRender, ProtectRender, updateElementVisibility} from './render.js';
import {ShowDiagnostics} from './report_diagnostics.js';

import $ from 'jquery';
import {GoogleCharts} from 'google-charts';

import 'datatables.net-dt/css/jquery.dataTables.css';
// import 'jquery-highlight/jquery.highlight.js'

// (1) Desired editor features:
import 'monaco-editor/esm/vs/editor/browser/controller/coreCommands.js';
// import 'monaco-editor/esm/vs/editor/browser/widget/codeEditorWidget.js';
// import 'monaco-editor/esm/vs/editor/browser/widget/diffEditorWidget.js';
// import 'monaco-editor/esm/vs/editor/browser/widget/diffNavigator.js';
// import 'monaco-editor/esm/vs/editor/contrib/bracketMatching/bracketMatching.js';
// import 'monaco-editor/esm/vs/editor/contrib/caretOperations/caretOperations.js';
// import 'monaco-editor/esm/vs/editor/contrib/caretOperations/transpose.js';
// import 'monaco-editor/esm/vs/editor/contrib/clipboard/clipboard.js';
// import 'monaco-editor/esm/vs/editor/contrib/codelens/codelensController.js';
// import 'monaco-editor/esm/vs/editor/contrib/colorPicker/colorDetector.js';
// import 'monaco-editor/esm/vs/editor/contrib/comment/comment.js';
// import 'monaco-editor/esm/vs/editor/contrib/contextmenu/contextmenu.js';
// import 'monaco-editor/esm/vs/editor/contrib/cursorUndo/cursorUndo.js';
// import 'monaco-editor/esm/vs/editor/contrib/dnd/dnd.js';
import 'monaco-editor/esm/vs/editor/contrib/find/findController.js';
// import 'monaco-editor/esm/vs/editor/contrib/folding/folding.js';
// import 'monaco-editor/esm/vs/editor/contrib/format/formatActions.js';
// import 'monaco-editor/esm/vs/editor/contrib/goToDeclaration/goToDeclarationCommands.js';
// import 'monaco-editor/esm/vs/editor/contrib/goToDeclaration/goToDeclarationMouse.js';
// import 'monaco-editor/esm/vs/editor/contrib/gotoError/gotoError.js';
// import 'monaco-editor/esm/vs/editor/contrib/hover/hover.js';
// import 'monaco-editor/esm/vs/editor/contrib/inPlaceReplace/inPlaceReplace.js';
// import 'monaco-editor/esm/vs/editor/contrib/linesOperations/linesOperations.js';
// import 'monaco-editor/esm/vs/editor/contrib/links/links.js';
// import 'monaco-editor/esm/vs/editor/contrib/multicursor/multicursor.js';
// import 'monaco-editor/esm/vs/editor/contrib/parameterHints/parameterHints.js';
// import 'monaco-editor/esm/vs/editor/contrib/quickFix/quickFixCommands.js';
// import 'monaco-editor/esm/vs/editor/contrib/referenceSearch/referenceSearch.js';
// import 'monaco-editor/esm/vs/editor/contrib/rename/rename.js';
// import 'monaco-editor/esm/vs/editor/contrib/smartSelect/smartSelect.js';
// import 'monaco-editor/esm/vs/editor/contrib/snippet/snippetController2.js';
// import 'monaco-editor/esm/vs/editor/contrib/suggest/suggestController.js';
// import 'monaco-editor/esm/vs/editor/contrib/toggleTabFocusMode/toggleTabFocusMode.js';
// import 'monaco-editor/esm/vs/editor/contrib/wordHighlighter/wordHighlighter.js';
// import 'monaco-editor/esm/vs/editor/contrib/wordOperations/wordOperations.js';
// import 'monaco-editor/esm/vs/editor/standalone/browser/accessibilityHelp/accessibilityHelp.js';
// import 'monaco-editor/esm/vs/editor/standalone/browser/inspectTokens/inspectTokens.js';
// import 'monaco-editor/esm/vs/editor/standalone/browser/iPadShowKeyboard/iPadShowKeyboard.js';
// import 'monaco-editor/esm/vs/editor/standalone/browser/quickOpen/quickOutline.js';
// import 'monaco-editor/esm/vs/editor/standalone/browser/quickOpen/gotoLine.js';
// import 'monaco-editor/esm/vs/editor/standalone/browser/quickOpen/quickCommand.js';
// import 'monaco-editor/esm/vs/editor/standalone/browser/toggleHighContrast/toggleHighContrast.js';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';

// (2) Desired languages:
// import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
import 'monaco-editor/esm/vs/language/css/monaco.contribution';
import 'monaco-editor/esm/vs/language/json/monaco.contribution';
// import 'monaco-editor/esm/vs/language/html/monaco.contribution';
import 'monaco-editor/esm/vs/basic-languages/bat/bat.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/coffee/coffee.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/cpp/cpp.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/csharp/csharp.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/csp/csp.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/css/css.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/dockerfile/dockerfile.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/fsharp/fsharp.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/go/go.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/handlebars/handlebars.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/html/html.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/ini/ini.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/java/java.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/less/less.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/lua/lua.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/markdown/markdown.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/msdax/msdax.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/mysql/mysql.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/objective-c/objective-c.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/pgsql/pgsql.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/php/php.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/postiats/postiats.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/powershell/powershell.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/pug/pug.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/python/python.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/perl/perl.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/julia/julia.contribution.js';

// import 'monaco-editor/esm/vs/basic-languages/r/r.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/razor/razor.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/redis/redis.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/redshift/redshift.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/ruby/ruby.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/sb/sb.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/scss/scss.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/solidity/solidity.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/rb/sql/sql.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/swift/swift.contribution.js';
// import 'monaco-editor/esm/vs/basic-languages/vb/vb.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/xml/xml.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/yaml/yaml.contribution.js';
import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution';
import 'monaco-editor/esm/vs/basic-languages/typescript/typescript.contribution';

import 'jstree/dist/jstree.min.js';
import 'jstree/dist/themes/default/style.css';
import 'jstree/dist/themes/mystyle.css';

// import 'jquery-table2excel/dist/jquery.table2excel.js';

//============================================================
// Global variable definitions
//============================================================
var progressIntervalVar;

var inactiveTimeout = 150*60000;
var statusUpdateTimeout = 60000;
var progressBarUpdateTimeout = 1000;

var curRowIdx = -1;

var user_type = 'f';

var snippetSearch = false;

var curSelectedNode = '';

var hdragging = false;
var vdragging = false;

const dt = require('datatables.net');
window.$.DataTable = dt;

window.self.MonacoEnvironment = {
    getWorkerUrl: function (moduleId, label) {
	// if (label === 'json') {
	// 	return './json.worker.bundle.js';
	// }
	// if (label === 'css') {
	// 	return './css.worker.bundle.js';
	// }
	// if (label === 'html') {
	// 	return './html.worker.bundle.js';
	// }
	// if (label === 'typescript' || label === 'javascript') {
	// 	return './ts.worker.bundle.js';
	// }
	return './editor.worker.bundle.js';
    }
};
//============================================================
// End Global variable definitions
//============================================================

function findGetParameter(parameterName) {
    let result = null, tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}
//============================================================
// Apply cookie settings
function findCookie(cname){
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++){
	const name = cookies[i].split("=")[0];
	let index = 0;
	while (name[index] === ' '){
	    index++;
	}
	
	const value = cookies[i].split("=")[1];
	if (name.substring(index) === cname){
	    return value;
	}
    }
    return "";
}

//============================================================
// Tab callback for refreshing rendering
//============================================================
$('#componentViewTab a').on('shown.bs.tab', function (e) {
    const targetTab = $(e.target).attr('id'); // Get the activated tab's href
    console.log('The shown tab is:', targetTab);
    if (targetTab === "tab2-tab-component") {
	window.dependencyviewtable.columns.adjust().draw();
    } else if (targetTab === "tab1-tab-component") {
	window.componentviewtable.columns.adjust().draw();
    }
});

$('#cveViewTab a').on('shown.bs.tab', function (e) {
    const targetTab = $(e.target).attr('id'); // Get the activated tab's href
    console.log('The shown tab is:', targetTab);
    if (targetTab === "tab2-tab-cve") {
	window.cveviewtable.columns.adjust().draw();
    } else if (targetTab === "tab1-tab-cve") {
	window.vulviewtable.columns.adjust().draw();
    }
});

$('#allViewTab a').on('shown.bs.tab', function (e) {
    const targetTab = $(e.target).attr('id'); // Get the activated tab's href
    console.log('The shown tab is:', targetTab);
    if (targetTab === "tab2-tab-allview") {
	window.allviewtable.columns.adjust().draw();
    }
});

//============================================================
// Editor initialization
//============================================================
const editor = monaco.editor.create(document.getElementById('src-code-editor'), {
    value: '',
    language: 'javascript',
    theme: "vs-dark",
    automaticLayout: true,
    fontSize: 12
});

//============================================================

const meditor = monaco.editor.create(document.getElementById('match-code-editor'), {
    value: '',
    language: 'javascript',
    theme: "vs-dark",
    readOnly: true,
    automaticLayout: true,
    fontSize: 12
});

//============================================================
// Editor utility functions
//============================================================

// [Unused]
function clearDecorations() {
    var elems = document.querySelectorAll(".myLineDecoration");
    // console.log(elems);

    [].forEach.call(elems, function(el) {
        el.classList.remove("myLineDecoration");
    });

    elems = document.querySelectorAll(".myLineDecoration_c");
    //console.log(elems);

    [].forEach.call(elems, function(el) {
        el.classList.remove("myLineDecoration_c");
    });
}
//============================================================
// [Unused]
function getHTMLPositionFromColumnOffset(lineNumber, columnOffset, editor) {
    const lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight);
    const lineCount = editor.getModel().getLineCount();

    // Check if the line number and column offset are within the range
    if (lineNumber >= 1 && lineNumber <= lineCount && columnOffset >= 1) {
        // Calculate the vertical position
        const verticalScrollPosition = (lineNumber - 1) * lineHeight;

        // Calculate the horizontal position based on the column offset
        const contentWidth = editor.getLayoutInfo().contentWidth;
        const fontInfo = editor.getOption(monaco.editor.EditorOption.fontInfo);
        const columnWidth = fontInfo.typicalFullwidthCharacterWidth; // Assuming monospace font
        const horizontalScrollPosition = columnOffset * columnWidth;

        return {
            top: verticalScrollPosition,
            left: horizontalScrollPosition,
            bottom: verticalScrollPosition + lineHeight,
            right: horizontalScrollPosition + contentWidth
        };
    } else {
        // Line number or column offset out of range
        return null;
    }
}
//============================================================
// [Unused]
function getOverlayWidget(left_pos, top_pos) {
    var overlayWidget = {
	domNode: (function () {
	    var domNode = document.createElement("div");
	    domNode.innerHTML = "My overlay widget";
	    domNode.style.background = "grey";
	    domNode.style.left = left_pos+"px";
	    domNode.style.top = top_pos+"px";
	    return domNode;
	})(),
	getId: function () {
	    return "my.overlay.widget";
	},
	getDomNode: function () {
	    return this.domNode;
	},
	getPosition: function () {
	    return null;
	},
    };
    return overlayWidget;
}
//============================================================
function addEditorInlineText(aEditor, lineNumber, columnNumber, text_str) {
    // Add a content widget (scrolls inline with text)
    var contentWidget = {
	domNode: (function () {
	    var domNode = document.createElement("div");
	    domNode.innerHTML = text_str;
	    // domNode.style.background = "grey";
	    domNode.style.backgroundColor = "rgba(128,128,128, 0.8)";
	    domNode.style.width = "600px";
	    domNode.style.fontStyle = "italic";
	    domNode.style.fontSize = "15px";
	    domNode.style.border = "thin solid #FF0000";
	    
	    // domNode.style.color = "white";
	    domNode.style.margin = "5px 5px 5px 5px";
	    // domNode.style.display = "inline-block";
	    // domNode.style.marginBottom = '1px';
	    domNode.style.lineHeight = '18px';
	    
	    return domNode;
	})(),
	getId: function () {
	    return "my.content.widget_"+lineNumber;
	},
	getDomNode: function () {
	    return this.domNode;
	},
	getPosition: function () {
	    return {
		position: {
		    lineNumber: lineNumber,
		    column: columnNumber
		},
		preference: [
		    monaco.editor.ContentWidgetPositionPreference.ABOVE,
		    monaco.editor.ContentWidgetPositionPreference.BELOW,
		],
	    };
	},
    };
    if (aEditor === editor) {
	insertSrcEditorContentWidgets(contentWidget);
    } else if (aEditor === meditor) {
	insertMatchEditorContentWidgets(contentWidget);
    }
    //editor.removeContentWidget(contentWidget);
}
//============================================================
function clearSrcEditorContentWidgets() {
    if (window.SrcEditorContentWidgets) {
	window.SrcEditorContentWidgets.forEach( (item, index) => {
	    editor.removeContentWidget(item);
	});
    }
}
//============================================================
function clearMatchEditorContentWidgets() {
    if (window.MatchEditorContentWidgets) {
	window.MatchEditorContentWidgets.forEach( (item, index) => {
	    meditor.removeContentWidget(item);
	});
    }
}
//============================================================
function insertSrcEditorContentWidgets(widget) {
    if (window.SrcEditorContentWidgets === undefined) {
	window.SrcEditorContentWidgets = [];
    }
    window.SrcEditorContentWidgets.push(widget);
    widget.allowEditorOverflow = true;
    editor.addContentWidget(widget);
}
//============================================================
function insertMatchEditorContentWidgets(widget) {
    if (window.MatchEditorContentWidgets === undefined) {
	window.MatchEditorContentWidgets = [];
    }
    window.MatchEditorContentWidgets.push(widget);
    meditor.addContentWidget(widget);
}
//============================================================
function setSrcEditorText(text) {
    clearSrcEditorContentWidgets();
    editor.getModel().setValue(text);
}
//============================================================
function setMatchEditorText(text) {
    clearMatchEditorContentWidgets();
    meditor.getModel().setValue(text);
}
//============================================================
// 2 5 5, 3 4 5, 4 4 4
function showOnlySrcEditor() {
    $("#match-code-area").hide(); //css("display", "none");
    // const filelist_width = $("#file-list").width();
    const srccode_width = 100*($("#code-container").width()-$("#file-list").width())/$("#code-container").width()-5; //-filelist_width;
    $("#src-code-area").css("width",srccode_width+"%")
    // console.log(filelist_width);
    console.log(srccode_width);
    $("#src-code-area").show();
}
//============================================================
function showBothEditors() {
    $("#match-code-area").show(); //css("display", "block");
    const srccode_width = 100*($("#code-container").width()-$("#file-list").width())/$("#code-container").width()-2; //-filelist_width;
    $("#src-code-area").css("width",srccode_width/2+"%")
    $("#match-code-area").css("width",srccode_width/2+"%")
}

//============================================================
// End of Editor utility functions
//============================================================

//============================================================
// Link status update
//============================================================
function enableLinks() {
    $("#code-submit").prop("disabled", false);
 
    $('#flist li').each( function() {
	$("#flist").jstree().enable_node(this.id);
    })
    
    $("#mf-table").find('a').each(function(e) {
	//console.log($(this).attr('href'));
	$(this).attr("class", "");
    });
    $('#file-list-header').prop("disabled", false);
    $('#clear-code').prop("disabled", false);
}
//============================================================
function disableLinks() {
    $("#code-submit").prop("disabled", true);
    $('#flist li').each( function() {
	$("#flist").jstree().disable_node(this.id);
    })
 
    $("#mf-table").find('a').each(function(e) {
	$(this).attr("class", "disabled");
    });
    $('#file-list-header').prop("disabled", true);
    $('#clear-code').prop("disabled", true);
}
//============================================================
function clearAllIntervalVars() {
    if (window.projectStatusIntervalVar) {
	clearInterval(window.projectStatusIntervalVar);
    }
    if (window.progressIntervalVar) {
	clearInterval(window.progressIntervalVar);
    }
    if (window.inactiveLogoutInternvalVar) {
	clearInterval(window.inactiveLogoutInternvalVar);
    }
}

//============================================================
// End Link status update
//============================================================


//============================================================
// Layout
//============================================================

$("#dragBar").mousedown(function(e) {
    e.preventDefault();
    hdragging = true;
    var resultDispDiv = $("#result-disp-div");
    var workingAreaDiv = $("#working-area-div");
    var ghostbar = $("<div>",
		     {id:"ghostbar",
		      css: {
			  width: resultDispDiv.outerWidth(),
			  top: resultDispDiv.offset().top,
			  left: resultDispDiv.offset().left,
		      }
		     }).appendTo(workingAreaDiv);
    
    $(document).mousemove(function(e) {
	ghostbar.css("top", (e.pageY+2));
    });
});
//============================================================
$(document).mouseup(function(e) {
    if (hdragging) {
	const percentage = ((e.pageY-$("#working-area-div").offset().top)/$("#working-area-div").height())*100;
	const mainPercentage = 100-percentage;
	$("#code-container").css("height", percentage +"%");
	$("#result-disp-div").css("height", mainPercentage +"%");
	$("#ghostbar").remove();
	
	$(document).unbind("mousemove");
	hdragging = false;
	
    } else if (vdragging) {
	const percentage = ((e.pageX-$("#code-container").offset().left)/$("#code-container").width())*100;
	let codeAreaPercentage;
	if ($("#match-code-area").css("display") === 'none') {
	    codeAreaPercentage = 100-percentage-2;
	} else {
	    codeAreaPercentage = (100-percentage-2)/2;
	    $("#match-code-area").css("width", codeAreaPercentage +"%");
	}
	$("#src-code-area").css("width", codeAreaPercentage +"%");
	$("#file-list").css("width", percentage +"%");
	console.log(percentage);

	$("#ghostbar").remove();
	
	$(document).unbind("mousemove");
	vdragging = false;
    }
});
//============================================================
$("#vdragBar").mousedown(function(e) {
    e.preventDefault();
    vdragging = true;
    var fileListDiv = $("#file-list");
    var codeContainerDiv = $("#code-container");
    var ghostbar = $("<div>",
		     {id:"ghostbar",
		      css: {
			  height: fileListDiv.outerHeight(),
			  top: fileListDiv.offset().top,
			  right: fileListDiv.offset().right,
		      }
		     }).appendTo(codeContainerDiv);
    
    $(document).mousemove(function(e) {
	ghostbar.css("right", (e.pageX+2));
    });
});
//============================================================
// End Layout
//============================================================

//============================================================
// Custom KB
//============================================================

function indexOnProgressHandler(evt) {
    let percent = evt.loaded/evt.total*100;
    console.log('Index upload progress: ' + percent + '%');
    $("#busy-indicator").show();
    window.isBusy = true;
}
//============================================================
// Index handle the response from the server
function onIndexReadyStateChangeHandler(evt) {
    let status, text, readyState;

    try {
	readyState = evt.target.readyState;
	text = evt.target.responseText;
	status = evt.target.status;
    }
    catch(err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
	return;
    }

    // $("#submit-project-div").modal('hide');

    $("#busy-indicator").hide();
    window.isBusy = false;
    
    if (readyState == 4 && (status == 200 || status == 500 || status == 401) && evt.target.responseText) {
	const robj = JSON.parse(text);

	if ('status' in robj) {
	    if (robj['status'] === 'error') {
		ShowDiagnostics("Server Error", robj['reason']);
	    } else if (robj['status'] === 'success') {
		$("#index-custom-div").modal('hide');
		ShowDiagnostics("Indexing successful", robj['reason'] + ". Took "+ robj['elapse_time']+" seconds.");
	    }
	} else {
	    ShowDiagnostics("Server Error", "unknown");
	}
    }
}

//============================================================
// Custom KB Index upload
const indexForm = document.getElementById('index-uploader');
indexForm.onsubmit = function(e) {
    e.preventDefault();
    let formData = new FormData(indexForm);
 
    const author = $("#repos-author").val();
    if (author.trim() === '') {
	$("#repos-author").css("border-color", "red");
	// let invalidInfo = window.confirm("Author field is required.");
	return false;
    } else {
	$("#repos-author").css("border-color", "");
    }
    
    const reposname = $("#repos-name").val();
    if (reposname.trim() === '') {
	$("#repos-name").css("border-color", "red");
	// let invalidInfo = window.confirm("Repository field is required.");
	return false;
    } else {
	$("#repos-name").css("border-color", "");
    }
    
    const version = $("#repos-version").val();
    if (version.trim() === '') {
	$("#repos-version").css("border-color", "red");
	// let invalidInfo = window.confirm("Version field is required.");
	return false;
    } else {
	$("#repos-version").css("border-color", "");
    }
    const desc = $("#repos-desc").val();
    const published_at = $("#repos-publish").val();
    const license = $("#repos-license").val();
    const score = $("#repos-score").val();
    const dnlink = $("#repos-download").val();

    const int_score = parseInt(score);
    if ((int_score === NaN) || (int_score < 0) || (int_score > 100)) {
	let invalidScore = window.confirm("Invalid score. Score value must be between 0 and 100 inclusive.");
	return false;
    }
    
    const fi = document.getElementById('index-upload-form');
    if (fi.files.length > 0) {
	let filetoobig = false;
	let bigfilename = '';
	let bigfsk;
	for (let i=0; i < fi.files.length; i++) {
	    const fsize = fi.files[i]['size'];
	    const fsk = Math.round(fsize/1024/1024);
	    if (fsk >= 100) {
		filetoobig = true;
		bigfilename = fi.files[i]['name'];
		bigfsk = fsk.toString();
		break;
	    }
	}
	if (filetoobig) {
	    let fileSizeLimitExceeded = window.confirm("The size of selected file '"+bigfilename +"' is "+bigfsk +"M, which exceeded the file size limit 10M");
	    return false;
	}
    } else {
	let noFileSelected = window.confirm("No file is selected.");
	return false;
    }
    
    const xhr = new XMLHttpRequest();
    xhr.timeout = 60000;
    // Add any event handlers here...
    xhr.upload.addEventListener('progress', indexOnProgressHandler, false);
    xhr.addEventListener('readystatechange', onIndexReadyStateChangeHandler, false);

    xhr.onerror = function () {
	ShowDiagnostics("Server Error", this.status+" "+xhr.responseText);
    };
	
    xhr.ontimeout = function() {
	// Display status: timeout
	ShowDiagnostics("Server Timeout Error", xhr.responseText);
    };
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
    formData.append('user', user);
    formData.append('token', token);
    formData.append('author', author);
    formData.append('artifact', reposname);
    formData.append('version', version);
    formData.append('license', license);
    formData.append('published_at', published_at);
    formData.append('description', desc);
    formData.append('score', score);
    formData.append('download_link', dnlink);
    
    xhr.open('POST', "https://www.deepfoss.com/custom2/pkbindex", true);
    xhr.send(formData);

    // $("#submit-project-div").modal('hide');
    // $('#submit-project-close').click();
    return false; // To avoid actual submission of the form
}

//============================================================
// End Custom KB
//============================================================

//============================================================
// Project submit
//============================================================

//============================================================
// updateNodeProjectStatus while job is being processed
function updateNodeProjectStatus(tree, obj) {
    let jsonNodes = $('#flist').jstree(true).get_json('#', { flat: false });

    $.each(jsonNodes, function (idx, val) {
	let node = tree.get_node($(val).attr('id'));
	let node_text = tree.get_text(node);

	let proj = extract_folder_name(node_text);
	if (proj in obj) {
	    let newText;
	    if (obj[proj] === 'started') {
		updateProjNodeToProcessing(node, tree);
	    } else if (obj[proj] === 'finished') {
		setNodeAndAllChildrenCacheStatus(node, tree, true);
		updateNodeCacheStatus(node, tree, true);
	    } else {
		console.log(proj);
		console.log(obj);
	    }
	    
	}
    });
    
    let allDone = true;
    Object.keys(obj).forEach((item, index) => {
	if (obj[item] !== 'finished') {
	    allDone = false;
	}
    });
    if (allDone) {
	console.log("clearInterval called");
	clearInterval(window.projectStatusIntervalVar);
	let user = window.sessionStorage.getItem("cached_user");
	let token = window.sessionStorage.getItem("cached_token");
	OpenProjFcn(user, token);
    }
}
//============================================================
// updateProjectStatus by requesting checkuserprojects
async function updateProjectStatus() {
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
    
    const queryObj = JSON.stringify({"user": user, "token": token});
    let prom = runQuery("POST", "https://www.deepfoss.com/local2/checkuserprojects", queryObj, scanJobWait, scanJobDone);
    try {
	let txtResponse = await prom;
	let tobj = JSON.parse(txtResponse);

	if ('status' in tobj && tobj['status'] === 'error') {
	    if ('reason' in tobj) {
		throw tobj['reason'];
	    } else {
		throw "unknown server error";
	    }
	}
	    
	updateNodeProjectStatus($('#flist').jstree(), tobj);
	
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
    }
}
//============================================================
function renderFileTree(robj) {
    //init_file_trees([]);
    // $('#flist').jstree("destroy").empty();
    console.log("renderFileTree");
    $('#flist').jstree().settings.core.data = robj['ftree']['children'];
    $('#flist').jstree().refresh();
    // $("#demo4").jstree(true).refresh_node("node_1");
}
//============================================================
const OpenProjFcn = async function (user, token) {
    let queryObj;
    if ($('#filetreeoption').is(":checked")) {
	queryObj = JSON.stringify({'user': user, 'token': token, 'option': 'show_match_only'});
    } else {
	queryObj = JSON.stringify({'user': user, 'token': token});
    }
    console.log("queryObj");
    console.log(queryObj);
    
    let prom;
    try {
	if (user === 'df4ai@deepfoss.com') {
	    prom = runQuery("POST", "https://www.deepfoss.com/local2/getprojecttree", queryObj, getFileWait, getFileDone);
	} else {
	    prom = runQuery("POST", "https://www.deepfoss.com/local2/getfiletree", queryObj, getFileWait, getFileDone);
	}
	let txtResponse = await prom;
	let robj = JSON.parse(txtResponse);
	if ('status' in robj && robj['status'] === 'error') {
	    ShowDiagnostics("Server Error", robj['reason']);
	    return false;
	} else {
	    renderFileTree(robj);
	    // window.sessionStorage.setItem("cached_ftree", JSON.stringify(robj));
    
	    return true;
	}
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
	return false;
    }
}
//============================================================
// Project upload
function onprogressHandler(evt) {
    let percent = evt.loaded/evt.total*100;
    console.log('Upload progress: ' + percent + '%');
    $("#busy-indicator").show();
    window.isBusy = true;
    $("#submit-project-div").modal('hide');
}
//============================================================
const form = document.getElementById('submit-project-uploader');
form.onsubmit = function(e) {
    e.preventDefault();
    let formData = new FormData(form);
    let projName = $("#project-name").val();
    
    var checkboxes = document.querySelectorAll('input[name="analysisscope"]');
    var checkedValues = [];
    checkboxes.forEach(function(checkbox) {
	if (checkbox.checked) {
	    checkedValues.push(checkbox.value);
	}
    });
    const scope_str = checkedValues.join(',');

    if (projName.trim() === "") {
	let useDefaultProjName = window.confirm("The project name is empty. Click Cancel to reenter a valid project name or click OK to use a UUID project name.");
	if (!useDefaultProjName) return false;
    } else {
	projName = projName.replace(/ |\.|\//g, '_');
	
	let tree = $('#flist').jstree(true);
	let jsonNodes = tree.get_json('#', { flat: false });
	let hasConflict = false;
	$.each(jsonNodes, function (idx, val) {
	    let node = tree.get_node($(val).attr('id'));
	    let origText = extract_folder_name(tree.get_text(node));
	    if (origText === projName) {
		hasConflict = true;
		return;
	    }
	});
	if (hasConflict) {
	    let useDefaultProjName = window.confirm("Project '"+projName+"' already exists. Click Cancel to reenter a valid project name.");
	    return false;
	    // if (!useDefaultProjName) return false;
	}
    }

    const fi = document.getElementById('submit-project-form');
    if (fi.files.length > 0) {
	let filetoobig = false;
	let bigfilename = '';
	let bigfsk;
	for (let i=0; i < fi.files.length; i++) {
	    const fsize = fi.files[i]['size'];
	    const fsk = Math.round(fsize/1024/1024);
	    if (fsk >= 100) {
		filetoobig = true;
		bigfilename = fi.files[i]['name'];
		bigfsk = fsk.toString();
		break;
	    }
	}
	if (filetoobig) {
	    let fileSizeLimitExceeded = window.confirm("The size of selected file '"+bigfilename +"' is "+bigfsk +"M, which exceeded the file size limit 100M");
	    return false;
	}
    } else {
	let noFileSelected = window.confirm("No file is selected.");
	return false;
    }
    
    const xhr = new XMLHttpRequest();
    xhr.timeout = 60000;
    // Add any event handlers here...
    xhr.upload.addEventListener('progress', onprogressHandler, false);
    xhr.addEventListener('readystatechange', onProjectSubmitReadyStateChangeHandler, false);

    xhr.onerror = function () {
	ShowDiagnostics("Server Error", this.status+" "+xhr.responseText);
    };
	
    xhr.ontimeout = function() {
	// Display status: timeout
	ShowDiagnostics("Server Timeout Error", xhr.responseText);
    };
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
    formData.append('user', user);
    formData.append('token', token);
    formData.append('projectname', projName);
    /*
    if (false) { //user_type === 'f') {
	formData.append('scope', 'foss');
    } else {
	const scope = DBScopePrevSelected();
	if (scope === 'static') {
	    formData.append('scope', 'static');
	} else if (scope === 'composition') {
	    formData.append('scope', 'composition');
	} else {
	    formData.append('scope', 'all');
	}
	console.log("analysis scope "+scope);
    }
    */

    formData.append('scope',scope_str);
    console.log("analysis scope "+scope_str);
    
    if (window.sessionStorage.getItem(projName)) {
	window.sessionStorage.removeItem(projName);
    }
    curSelectedNode = '';
    
    xhr.open('POST', "https://www.deepfoss.com/local2/submitproject", true);
    xhr.send(formData);

    incrementURLBOMTimeStamp();
    
    return false; // To avoid actual submission of the form
}
//============================================================
function processingScanProject(robj, renderTree) {
    if ('status' in robj && robj['status'] === 'error') {
	if (window.projectStatusIntervalVar) {
	    clearInterval(window.projectStatusIntervalVar);
	}
	ShowDiagnostics("Server Error", robj['reason']);
    } else {
	if (renderTree) {
	    renderFileTree(robj);
	}
	updateProjectStatus();
	
	if ('proj_name' in robj) {
	    const projname = robj['proj_name'];
	    if ('proj_files' in robj) {
		const filenames = robj['proj_files'];
		filenames.forEach(it => {
		    if (window.sessionStorage.getItem(it)) {
			window.sessionStorage.removeItem(it);
			console.log("removed: "+ it);
		    }
		});
	    }
	}
	// start timeInterval until all pending projects are finished
	// or no active job for user
	console.log("setInterval for projectStatusIntervalVar called");
	
	if (window.projectStatusIntervalVar) {
	    clearInterval(window.projectStatusIntervalVar);
	}
	window.projectStatusIntervalVar = setInterval(updateProjectStatus, statusUpdateTimeout); // 1 minute
    }
}
//============================================================
// Handle the response from the server
function onProjectSubmitReadyStateChangeHandler(evt) {
    let status, text, readyState;

    try {
	readyState = evt.target.readyState;
	text = evt.target.responseText;
	status = evt.target.status;
    }
    catch(err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
	return;
    }

    $("#busy-indicator").hide();
    window.isBusy = false;
  
    if (readyState == 4 && (status == 200 || status == 400) && evt.target.responseText) {
	const robj = JSON.parse(text);
	    
	// processingScanProject(robj, true);
	
	if ('status' in robj && robj['status'] === 'error') {
	    if (window.projectStatusIntervalVar) {
		clearInterval(window.projectStatusIntervalVar);
	    }
	    ShowDiagnostics("Server Error", robj['reason']);
	} else if ('ftree' in robj) {
	    renderFileTree(robj);
	    updateProjectStatus();
	    
	    if ('proj_name' in robj) {
		const projname = robj['proj_name'];
		if ('proj_files' in robj) {
		    const filenames = robj['proj_files'];
		    filenames.forEach(it => {
			if (window.sessionStorage.getItem(it)) {
			    window.sessionStorage.removeItem(it);
			    console.log("removed: "+ it);
			}
		    });
		}
	    }

	    console.log("setInterval for projectStatusIntervalVar called");

	    if (window.projectStatusIntervalVar) {
		clearInterval(window.projectStatusIntervalVar);
	    }
	    window.projectStatusIntervalVar = setInterval(updateProjectStatus, statusUpdateTimeout); // 1 minute
	}
	// $('#file-list-header').click();
    }
}

//============================================================
// End Project submit
//============================================================

//============================================================
// Static analysis display
//============================================================

function displayStaticResultsInEditor(title_str) {
    const pattern = /(\d+,)|(\d+,)|(\d+,)|(\d+,)|(\{\{.*?\}\})/g;
    const matches = title_str.match(pattern);
    let startLine_v = [], endLine_v = [], startCol_v = [], endCol_v = [], msg_v = [];

    if (!matches) return;

    const len = matches.length/5;
    
    for (let k=0; k<len; k++) {
	const startLine = parseInt(matches[k*5].slice(0,-1));
	const endLine = parseInt(matches[k*5+1].slice(0,-1));
	const startCol = parseInt(matches[k*5+2].slice(0,-1));
	const endCol = parseInt(matches[k*5+3].slice(0,-1));
	const msg = escapeHtml(matches[5*k+4].slice(2,-2));

	startLine_v.push(startLine);
	endLine_v.push(endLine);
	startCol_v.push(startCol);
	endCol_v.push(endCol);
	msg_v.push(msg);
    }
    
    // Clear original decorations
    if (window.sDecorations) {
	window.sDecorations = editor.deltaDecorations(window.sDecorations, []);
    }

    let decor_v = [];
    for (let k=0; k<startLine_v.length; k++) {
	let decor = {range: new monaco.Range(startLine_v[k], startCol_v[k]+1,
					     endLine_v[k], endCol_v[k]+1),
		     options: {isWholeLine: false,
			       linesDecorationsClassName: 'myLineDecoration',
			       className: 'highlighted-region'
			      }
		    };
	decor_v.push(decor);
	console.log(endCol_v[k]);
	addEditorInlineText(editor, startLine_v[k]-1, endCol_v[k]+30, msg_v[k]);
    }
    window.sDecorations = editor.deltaDecorations([],decor_v);
    editor.revealLineInCenter(startLine_v[0]);
}

function loginWait() {
    console.log("waiting for login");
}

function loginDone() {
    console.log("login done");
}

async function inactiveLogout() {
    var now = new Date();
    console.log("Inactive for 60 mins, logging out at "+now.toUTCString());
    // $("#logout_link").click();
}

function incrementURLBOMTimeStamp() {
    const url = $("#ExportAllViews")[0].href;
    console.log(url);
    
    const pos = url.indexOf("&ts=");
    let ts = parseInt(url.substr(pos+"&ts=".length));

    console.log(ts);
    
    ts += 1;
    const nurl = url.substr(0, pos)+"&ts="+ts.toString();
    $("#ExportAllViews")[0].href = nurl;
    
    console.log($("#ExportAllViews")[0].href);
}

window.onclick = function(event) {
    if (!window.sessionStorage.getItem("cached_user")) {
	return;
    }

    // Make scan-progress bar disappear only code-submit button is enabled
    if (!$("#code-submit").prop("disabled") && $("#scan-progress").is(":visible")) {
	$("#scan-progress").hide();
    }
    
    if (window.inactiveLogoutIntervalVar) {
	clearInterval(window.inactiveLogoutIntervalVar);
    }
    
    if(!event.target.matches('#logout_link')) {
	var now = new Date();
	console.log("Start inactive count down at "+now.toUTCString());
	window.inactiveLogoutIntervalVar = setInterval(inactiveLogout, inactiveTimeout); // 15 minute
    }
    
    if (event.target.matches('#opMode')) {
	document.getElementById("opMode").classList.toggle('show');
    } else {
	const myDropdown = document.getElementById("modeDD");
	if (myDropdown.classList.contains('show')) {
	    myDropdown.classList.remove('show');
	}
    }
    
    if (event.target.matches('#fileMode')) {
	if (OPModePrevSelected() === 'snippet') {
	    OPModeSwitch('file', user_type);
	    // editor.updateOptions({readOnly: true});
	    document.cookie = "opmode_type=file";
	}
    }
    else if (event.target.matches('#snippetMode')) {
	if (OPModePrevSelected() === 'file') {
	    OPModeSwitch('snippet', user_type);
	    // editor.updateOptions({readOnly: false});
	    document.cookie = "opmode_type=snippet";
	}
    }
}

const MatchNavigator = {
    match_vec: [],
    cur_pos: -1,

    SetMatchVec: function (_mv) {
	this.match_vec = _mv;
	if (this.match_vec.length > 1) {
	    this.cur_pos = 0;
	    this.ShowButtons();
	    this.UpdateButtons();
	} else {
	    this.HideButtons();
	}
    },
    HideButtons: function () {
	$("#move-down").hide();
	$("#move-up").hide();
    },
    ShowButtons: function () {
	$("#move-down").show();
	$("#move-up").show();
    },
    UpdateButtons: function () {
	if (this.cur_pos === 0) {
	    $("#move-down").prop("disabled", false);
	    $("#move-up").prop("disabled", true);
	} else if (this.cur_pos === this.match_vec.length-1) {
	    $("#move-down").prop("disabled", true);
	    $("#move-up").prop("disabled", false);
	} else {
	    $("#move-down").prop("disabled", false);
	    $("#move-up").prop("disabled", false);
	}
    },
    MoveUp: function () {
	if (this.cur_pos > 0) {
	    this.cur_pos --;
	    let item = this.match_vec[this.cur_pos];
	    editor.revealLineInCenter(item['src'][0]+1);
	    meditor.revealLineInCenter(item['dst'][0]+1);

	    if (window.sDecorations) {
		let cur = {range: new monaco.Range(item['src'][0]+1,
						   1,
						   item['src'][1]+1,
						   150),
			   options: {isWholeLine: false,
				     linesDecorationsClassName: 'myLineDecoration_c'}};

		let lenDecor = window.sDecorations.length;
		// window.sDecorations = editor.deltaDecorations([window.sDecorations[lenDecor-1]], [cur]);
		editor.deltaDecorations([window.sDecorations[lenDecor-1]], [cur]);
		
		cur = {range: new monaco.Range(item['dst'][0]+1,
					       1,
					       item['dst'][1]+1,
					       150),
		       options: {isWholeLine: true,
				 linesDecorationsClassName: 'myLineDecoration_c'}};
		lenDecor = window.dDecorations.length;
		window.dDecorations = meditor.deltaDecorations([window.dDecorations[lenDecor-1]], [cur]);

	    }
	}
	this.UpdateButtons();
    },
    MoveDown: function () {
	if (this.cur_pos < this.match_vec.length-1) {
	    this.cur_pos ++;
	    let item = this.match_vec[this.cur_pos];
	    editor.revealLineInCenter(item['src'][0]+1);
	    meditor.revealLineInCenter(item['dst'][0]+1);

	    if (window.sDecorations) {
		let cur = {range: new monaco.Range(item['src'][0]+1,
						   1,
						   item['src'][1]+1,
						   150),
			   options: {isWholeLine: true,
				     linesDecorationsClassName: 'myLineDecoration_c'}};

		let lenDecor = window.sDecorations.length;
		// window.sDecorations = editor.deltaDecorations([window.sDecorations[lenDecor-1]], [cur]);
		editor.deltaDecorations([window.sDecorations[lenDecor-1]], [cur]);
		cur = {range: new monaco.Range(item['dst'][0]+1,
					       1,
					       item['dst'][1]+1,
					       150),
		       options: {isWholeLine: true,
				 linesDecorationsClassName: 'myLineDecoration_c'}};
		lenDecor = window.dDecorations.length;
		// window.dDecorations = meditor.deltaDecorations([window.dDecorations[lenDecor-1]], [cur]);
		meditor.deltaDecorations([window.dDecorations[lenDecor-1]], [cur]);

	    }
							  
	}
	this.UpdateButtons();
    }
};

const gMoveDown = MatchNavigator.MoveDown.bind(MatchNavigator);
$("#move-down").click(gMoveDown);

const gMoveUp = MatchNavigator.MoveUp.bind(MatchNavigator);
$("#move-up").click(gMoveUp);

function experiment_tree(treeData) {
    $('#treenode').jstree({
	'core' : {
	    "check_callback": true,
	    'data' : treeData
	},
	'plugins' : [ "types", "contextmenu"],
        'types' : {
	    'default' : {
                //'icon' : 'fa fa-angle-right fa-fw'
		'icon' : 'fa fa-folder fa-fw'
	    },
	    'f-open' : {
                'icon' : 'fa fa-folder-open fa-fw'
	    },
	    'f-closed' : {
                'icon' : 'fa fa-folder fa-fw'
	    },
	    'f-file': {
		'icon': 'fa fa-file fa-fw'
	    },
	    'f-empty': {
		'icon': 'fa fa-folder-o fa-fw'
	    }
        },
	"contextmenu":{         
	    "items": function($node) {
		var tree = $("#treenode").jstree(true);
		return {                       
		    "Remove": {
			"separator_before": false,
			"separator_after": false,
			"icon": 'far fa-trash-alt',
			"label": "<span style='font-size:10pt'>remove</span>",
			"action": function (obj) { 
			    tree.delete_node($node);
			    
			    // call server to delete
			}
		    }
		};
	    }
	}
    });
    
 $('#treenode').on("select_node.jstree", function (e, data) {

     //let fileName = $('#treenode').jstree().get_path(data.instance.get_node(data.selected[0]), '/');
     
     let fileName = data.instance.get_path(data.instance.get_node(data.selected[0]), '/');
     
     console.log("exp select: "+fileName);
     console.log(data.instance.get_node(data.selected[0]));
    }); 
}

function find_file_node_from_path(fpath) {
    let fpath_v = fpath.split("/");
    const tree = $('#flist').jstree(true);
    let jsonNodes = tree.get_json('#', { flat: false });
    for (let k=0; k<fpath_v.length; k++) {
	for (let idx=0; idx<jsonNodes.length; idx++) {
	    let cnode = jsonNodes[idx];
	    let cnode_text = tree.get_text(cnode);
	    if (k === 0) {
		cnode_text = extract_folder_name(cnode_text);
	    }
	    if (cnode_text === fpath_v[k]) {
		if (k === fpath_v.length-1) {
		    return cnode;
		} else {
		    jsonNodes = cnode.children;
		    break;
		}
	    }
	}
    }
    return null;
    
    //$.each(jsonNode, function (idx, val) {
    //console.log($(val).attr('id'));
    //});
    //const cn = $('#flist').jstree(true).get_node("#j1_21");
    //const ccn = $('#flist').jstree(true).get_node(cn.children[0]);
    //$('#flist').jstree(true).select_node(ccn.children[1]);
    // let fileName = get_purified_path(data.instance.get_node(data.selected[0]), $('#flist').jstree());
}

function extract_folder_name(fn) {
    let fc = fn;
    if (fc.startsWith("<span")) {
	let b = fc.indexOf("</i>")+"</i>".length+"&nbsp;".length;
	let e = fc.indexOf("</span>");
	fc = fc.substr(b, e-b);
    }
    return fc;
}

function get_purified_path(node, tree) {
    let fileName = tree.get_path(node, '/');
    console.log(fileName);
    if (fileName.startsWith("<span")) {
	let b = fileName.indexOf("</i>")+"</i>".length+"&nbsp;".length;
	let e = fileName.indexOf("</span>");
	let dir = fileName.substr(b, e-b);
	let pos = e+"</span>".length;
	fileName = dir+fileName.substr(pos);
    }
    return fileName;
}

function in_project_processing(node, tree) {
    let cnode = node;
    while (cnode.parent !== "#") {
	cnode = tree.get_node(cnode.parent);
    }
    return (cnode.data === 'dp');
}

function init_file_trees(treeData, showClearCache) {
    console.log("showclearcache: "+showClearCache);
    
    $('#flist').jstree({
	'core' : {
	    "check_callback": true,
	    'data' : treeData
	},
	'plugins' : [ "types","contextmenu", "search"],
        'types' : {
	    'default' : {
                //'icon' : 'fa fa-angle-right fa-fw'
		'icon' : 'fa fa-folder fa-fw'
	    },
	    'f-open' : {
                'icon' : 'fa fa-folder-open fa-fw'
	    },
	    'f-closed' : {
                'icon' : 'fa fa-folder fa-fw'
	    },
	    'f-open-cache': {
		'icon' : 'fa fa-folder-open fa-fw',
		"a_attr" : { "style" : cacheColor }
	    },
	    'f-closed-cache': {
		'icon' : 'fa fa-folder fa-fw',
		"a_attr" : { "style" : cacheColor }
	    },
	    'f-file': {
		'icon': 'fa fa-file fa-fw',
	    },
	    'f-file-cache': {
		//'icon': 'fa fa-file fa-fw',
		'icon': 'fas fa-exclamation-triangle',
		"a_attr" : { "style" : cacheColor }
	    },
	    'f-file-cache-e': {
		'icon': 'fa fa-file fa-fw',
		"a_attr" : { "style" : cacheColor }
	    },
	    'f-empty': {
		'icon': 'fa fa-folder-o fa-fw'
	    }
        },
	"contextmenu":{         
	    "items": function(node) {
		if (!$("#scan-progress").is(":hidden")) {
		    return;
		}
		const tree = $("#flist").jstree(true);
		
		if (in_project_processing(node, tree)) {
		    return;
		}
		
		if (node.parent === "#") {
		    return {                       
			"Remove": {
			    "separator_before": true,
			    "separator_after": true,
			    "icon": 'far fa-trash-alt',
			    "label": "<span style='font-size:10pt'>remove project</span>",
			    "action": function async (obj) { 
				const fileName = get_purified_path(node, tree);
				window.onDeleteFile(fileName, tree, node);
			    }
			},
			"Rescan": {
			    "separator_before": true,
			    "separator_after": true,
			    "icon": 'fas fa-redo',
			    "label": "<span style='font-size:10pt'>rescan project</span>",
			    "action": function async (obj) { 
				const projectName = get_purified_path(node, tree);
				window.onRescanProject(projectName, tree, node);
			    }
			},
			"View Compositions": {
			    "separator_before": true,
			    "separator_after": true,
			    "icon": 'fas fa-search',
			    "label": "<span style='font-size:10pt'>view compositions</span>",
			    "action": function (obj) {
				const fileName = get_purified_path(node, tree);
				window.onViewComponents(fileName);
			    }
			},
			"View Vulnerabilities": {
			    "separator_before": true,
			    "separator_after": true,
			    "icon": 'fas fa-search',
			    "label": "<span style='font-size:10pt'>view vulnerabilities</span>",
			    "action": function (obj) {
				const fileName = get_purified_path(node, tree);
				window.onViewCVEs(fileName);
			    }
			},
			"View Licenses": {
			    "separator_before": true,
			    "separator_after": true,
			    "icon": 'fas fa-search',
			    "label": "<span style='font-size:10pt'>view licenses</span>",
			    "action": function (obj) {
				const fileName = get_purified_path(node, tree);
				window.onViewLicenses(fileName);
			    }
			},
			"View Static Analysis": {
			    "separator_before": true,
			    "separator_after": true,
			    "icon": 'fas fa-search',
			    "label": "<span style='font-size:10pt'>view static analysis</span>",
			    "action": function (obj) {
				const fileName = get_purified_path(node, tree);
				window.onViewStaticResult(fileName);
			    }
			}
		    };
		}
		    
		return {                       
		    "Remove": {
			"separator_before": true,
			"separator_after": true,
			"icon": 'far fa-trash-alt',
			"label": "<span style='font-size:10pt'>remove</span>",
			"action": function async (obj) { 
			    const fileName = get_purified_path(node, tree);
			    window.onDeleteFile(fileName, tree, node);
			}
		    }
		};
	    }
	},
	"search": {
	    "case_sensitive": false,
	    "show_only_matches": true
	}
    });
     
    $('#flist').on("changed.jstree", function (e, data) {
	let tree = data.instance;
	let jsonNodes = tree.get_json('#', { flat: true });
	$.each(jsonNodes, function (i, val) {
	    let cnode = tree.get_node($(val).attr('id'));
	    if (cnode.data == 'fc') {//cnode.data.length >= 2 && cnode.data.substring(0,2) === 'fc') {
		tree.set_type(cnode, 'f-file-cache');
		//let origText = extract_folder_name(tree.get_text(cnode));
		//let newText = "<span>"+origText+"&nbsp;<i class='fas fa-exclamation-triangle'></i></span>";
		//tree.rename_node(cnode, newText);
	    } else if (cnode.data == 'fce') {
		tree.set_type(cnode, 'f-file-cache-e');
	    } else if (cnode.data === 'f') {
		tree.set_type(cnode, 'f-file');
	    } else if (cnode.data === 'dc') {
		tree.set_type(cnode, 'f-closed-cache');
		if (cnode.parent === '#') {
		    let origText = extract_folder_name(tree.get_text(cnode));
		    let newText = "<span><i class='far fa-check-circle'></i>&nbsp;"+origText+"</span>";
		    tree.rename_node(cnode, newText);
		}
	    } else if (cnode.data === 'dp') {
		tree.set_type(cnode, 'f-closed');
		if (cnode.parent === '#') {
		    let origText = extract_folder_name(tree.get_text(cnode));
		    let newText = "<span><i class='fas fa-cog fa-spin'></i>&nbsp;"+origText+"</span>";
		    tree.rename_node(cnode, newText);
		}
	    } else if (cnode.data === 'd') {
		tree.set_type(cnode, 'f-closed');
		if (cnode.parent === '#') {
		    let origText = extract_folder_name(tree.get_text(cnode));
		    let newText = "<span><i class='fas fa-cog'></i>&nbsp;"+origText+"</span>";
		    tree.rename_node(cnode, newText);
		}
	    } else if (cnode.children.length === 0) {
		tree.set_type(cnode, 'f-empty');
	    }
	})
    });

    $('#flist').on("select_node.jstree", function (e, data) {
	const startTime = new Date();
	if (data.selected[0] === curSelectedNode) {
	    if (data.instance.get_type(data.node) === 'dc') {
		return;
	    }
	} else {
	    curSelectedNode = data.selected[0];
	}

	// let fileName = get_purified_path(data.instance.get_node(data.selected[0]), data.instance);
	let fileName = get_purified_path(data.node, data.instance);
	const stype = data.instance.get_type(data.node);
	if (stype === 'f-file' || stype === 'f-file-cache' || stype === 'f-file-cache-e') {
	    console.log(fileName);
	    window.onTreeFileSelected(e, fileName);
	} else if (data.node.parent === "#") {
	    window.onProjSelected(e, fileName);
	}
	const endTime = new Date();
	var timeDiff = endTime - startTime; //in ms
	timeDiff /= 1000;

	// get seconds 
	// var seconds = Math.round(timeDiff);
	console.log(timeDiff + " seconds");
    });
    
    $("#flist")
	.on('open_node.jstree', function(evt, data) {
	    if (data.node.data === 'd') {
		data.instance.set_type(data.node,'f-open');
	    } else if (data.node.data === 'dc') {
		data.instance.set_type(data.node,'f-open-cache');
	    }
	})
	.on('close_node.jstree', function(evt, data) {
	    if (data.node.data === 'd') {
		data.instance.set_type(data.node,'f-closed');
	    } else if (data.node.data === 'dc') {
		data.instance.set_type(data.node,'f-closed-cache');
	    }
	})
	.on('search.jstree', function (nodes, str, res) {
	    if (str.nodes.length===0) {
		$('#flist').jstree(true).hide_all();
	    }
	});
    
    $("#search-flist").keyup( function() {
	$("#flist").jstree().show_all();
	var searchString = $(this).val();
	if (searchString.trim().length === 0) {
	    $("#flist").jstree().search('');
	} else if (searchString.length > 2) { // search minimum chars
	    $("#flist").jstree().search(searchString);
	} 
    });
    $("#search-flist").on('search', function() {
	if ($(this).val().trim().length === 0) {
	    $("#flist").jstree().show_all();
	    $("#flist").jstree().search('');
	} 
    });
    
}

function to_text_link(url_text) {
    if (url_text === "") {
	return "";
    }
    
    let urlPrefix = "https://";
    let pos = url_text.indexOf(urlPrefix);
    if (pos === -1) {
	urlPrefix = "http://";
	pos = url_text.indexOf(urlPrefix);
    }
    let dispStr = url_text;
    if (pos !== -1) {
	dispStr = url_text.substr(pos+urlPrefix.length);
    } 
    return "<a href=\""+url_text+"\" style=\"color:"+frontColor+ "\" target=\"_blank\"><u>"+dispStr+"</u></a>";
}

function to_text_download_link(url_text) {
    if (url_text === "" || url_text == "null") {
	return "";
    }
    let s = url_text.split("/");
    let disp;
    if (s.length > 2) {	
	disp = s[s.length-2]+"/"+s[s.length-1];
    }
    return "<a title=\"Click to download the release tar file\" href=\""+url_text+"\" style=\"color: "+frontColor+"\" target=\"_blank\"><u>"+disp+"</u></a>";
}

function to_text_repos_link(repos_name) {
    let githubLink = "https://github.com/";
    return "<a href=\""+githubLink+repos_name+"\" style=\"color:"+frontColor+ "\" target=\"_blank\"><u>"+repos_name+"</u></a>";
}

function to_check_component_link(repos_name) {
    return "<a title=\"Click to check CVE impact for component ["+repos_name+"]\"><u><span onclick=\"window.onOpenCheckComponent(event)\"><i class=\"far fa-hand-point-right\"></i></span></u></a>";
}

// https://nvd.nist.gov/vuln/detail/CVE-2015-0284
function to_text_cve_link(cve_id) {
    if (cve_id.startsWith('CVE-')) {
	let url_text = "https://nvd.nist.gov/vuln/detail/"+cve_id;
	return "<a href=\""+url_text+"\" style=\"color:"+frontColor+"\" target=\"_blank\"><u>"+cve_id+"</u></a>";
    } else {
	if (!isNaN(parseInt(cve_id))) {
	    let url_text = "https://github.com/nodejs/security-wg/blob/main/vuln/npm/"+cve_id+".json";
	    return "<a href=\""+url_text+"\" style=\"color:"+frontColor+"\" target=\"_blank\"><u>"+"npm: "+cve_id+"</u></a>";
	}
	return cve_id;
    }
}

function to_text_cve_link_plain(cve_id) {
    let url_text = "https://nvd.nist.gov/vuln/detail/"+cve_id;
    return "<a href=\""+url_text+"\" target=\"_blank\"><u>"+cve_id+"</u></a>";
}

function to_text_cwe_link(cwe_id) {
    const pos = cwe_id.indexOf("-");
    const cwe_num = cwe_id.substr(pos+1);
    let url_text = "https://cwe.mitre.org/data/definitions/"+cwe_num+".html";
    return "<a href=\""+url_text+"\" target=\"_blank\"><u>"+cwe_id+"</u></a>";
}

function to_text_github_commit_link(commit, repos, fpath_sha) {
    let url_text = "https://github.com/"+repos+"/commit/"+commit+"?diff=split";
    //sha256(fpath).then(function(fpath_sha) {return fpath_sha});
    // let fpath_sha = utils.digest(fpath);
    if (fpath_sha !== '') {
	url_text += "#diff-"+fpath_sha;
    }
    return "<a href=\""+url_text+"\" style=\"color:"+frontColor+"\" target=\"_blank\"><u>"+commit+"</u></a>";
}

function all_cve_links(cve_ids_str) {
    if (cve_ids_str === "") {
	return "";
    }
    
    let vec = cve_ids_str.split(",");
    let rs = "";
    let upperLen = 3;
    let appendStr = "";
    if (vec.length < 3) {
	upperLen = vec.length;
    } else {
	appendStr = ",&nbsp;...("+vec.length.toString()+")";
    }
    for (let k=0; k<upperLen; k++) {
	rs += to_text_cve_link(vec[k]);
	rs += ",&nbsp;";
    }
    
    return rs.substr(0, rs.length-7)+appendStr;
}

function getLangFromFileExt(filePath) {
    let fext = filePath.split('.').pop();
    if (typeof fext === "undefined") {
	return "text/plain";
    }
    fext = fext.toLowerCase();
    const languages = {
	'bat': 'bat',
	'c': 'cpp',
	'cc': 'cpp',
	'coffee': 'coffee',
	'cpp': 'cpp',
        'css': 'css',
	'cxx': 'cpp',
	'go': 'go',
	'h': 'cpp',
	'hpp': 'cpp',
        'htm': 'html',
        'html': 'html',
	'java': 'java',
	'jl': 'julia',
        'js': 'javascript',
	'json': 'json',
	'md': 'markdown',
	'pm': 'perl',
	'pl': 'perl',
	'php': 'php',
	'py': 'python',
	'rb': 'ruby',
	'scss': 'scss',
	'sh': 'powershell',
        'ts': 'typescript',
        'txt': 'text',
	'xml': 'xml',
	'yaml': 'yaml',
	'yml': 'yaml'
	
    }
    if (fext in languages) {
	return languages[fext];
    } else {
	return "text/plain";
    }
}

function disp_cached_scan_result(obj, fileName, isGetfile) {

    let sr;
    if (typeof obj['scan_result'] === 'string') {
	 sr = JSON.parse(obj['scan_result']);
    } else {
	sr = obj['scan_result'];
    }
    renderMatchFileTable(sr[fileName]['full_match'],sr[fileName]['snippet_match'], true);

    //$("#current-view-display").hide();
    //$("#src-match-file").show();
    let scope = 'oss';
    
    if ('scope' in sr) {
	scope = sr['scope'];
    }
    
    $("#src-match-file").html("Search '"+scope+"' scan results for <i>"+fileName+"</i>");
    
    //window.sessionStorage.setItem("cached_full_match", JSON.stringify(sr[fileName]['full_match']));
    //window.sessionStorage.setItem("cached_snippet_match", JSON.stringify(sr[fileName]['snippet_match']));
    if (isGetfile) {
	$('#minfo-display').html("[updated scan result from cache]");
    } else {
	$('#minfo-display').html("[found cached scan result]");
    }
    $('#src-info-display').html(fileName);
    setMatchEditorText('');
    // meditor.getModel().setValue('');
    MatchNavigator.HideButtons();
}

window.onTogglePassword = async (e) => {
    console.log(e.target);
    const inputId = e.target.id.slice("toggle-".length);
    const passwordInput = document.getElementById(inputId);
    const togglePassword = e.target;

    if (passwordInput.type === 'password') {
	passwordInput.type = 'text';
	togglePassword.classList.remove('fa-eye');
	togglePassword.classList.add('fa-eye-slash');
    } else {
	passwordInput.type = 'password';
	togglePassword.classList.remove('fa-eye-slash');
	togglePassword.classList.add('fa-eye');
    }
}

window.onResetPassword = async () => {
    // e.preventDefault();
    $("#reset-password-div").modal(	    
    );
};

window.onRescanProject = async (projectName, tree, node) => {
    console.log(projectName);
    // e.preventDefault();
    $("#rescan-project-div").modal(
	    
    );
    $("#rescanprojectname").html(projectName);
};

window.onDeleteFile = async (fileName, tree, node) => {
    let delFile = window.confirm("Are you sure to delete "+fileName+"? Cancel or click OK to delete.");
    if (!delFile) return;

    if (window.sessionStorage.getItem(fileName)) {
	window.sessionStorage.removeItem(fileName);
    }
    
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
    
    let queryObj = JSON.stringify({"filename": fileName, "user":user, "token":token});
    let prom = runQuery("POST", "https://www.deepfoss.com/local2/deletefile", queryObj, getFileWait, getFileDone);
    try {
	let txtResponse = await prom;
	let robj = JSON.parse(txtResponse);
	if ('status' in robj && robj['status'] === 'error') {
	    ShowDiagnostics("Server Error", robj['reason']);
	} else {
	    let updateParent = false;
	    let parNode;
	    if (node.parent !== "#" && (node.data === 'f' || node.data === 'd')) {
		updateParent = true;
		parNode = tree.get_node(node.parent);
	    }
	    tree.delete_node(node);
	    if (updateParent) {
		updateCacheStatus(parNode, tree);
	    }

	    let cachedFileName = window.sessionStorage.getItem("cached_src_file");
	    if (cachedFileName && fileName === cachedFileName) {
		$('#src-info-display').html("");
		setSrcEditorText("");
		//editor.getModel().setValue("");
	    }
	    incrementURLBOMTimeStamp();
	}
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
    }
};

function clearParentCacheStatus(node, tree) {
    if (node.id === "#") return;
    
    let p = tree.get_node(node.parent);
    if (p.data === 'dc') {
	updateNodeCacheStatus(p, tree, false);
    }
    clearParentCacheStatus(p, tree);
}

function clearAllChildrenCacheStatus(node, tree) {
    setNodeAndAllChildrenCacheStatus(node, tree, false);
}

function setNodeAndAllChildrenCacheStatus(node, tree, toCache) {
    updateNodeCacheStatus(node, tree, toCache);
    let cs = node.children;
    cs.forEach(it => {
	let cn = tree.get_node(it);
	setNodeAndAllChildrenCacheStatus(cn, tree, toCache);
    });
}

function updateProjNodeToProcessing(node, tree) {
    node.data = 'dp';
    if ($("#"+node.id).hasClass("jstree-closed")) {
	tree.set_type(node, 'f-closed');
    } else {
	tree.set_type(node, 'f-open');
    }
    if (node.parent === '#') {
	const origText = extract_folder_name(tree.get_text(node));
	const newText = "<span><i class='fas fa-cog fa-spin'></i>&nbsp;"+origText+"</span>";
	tree.rename_node(node, newText);
    }
}

function updateNodeCacheStatus(node, tree, toCached) {
    if (toCached) {
	if (node.data[0] === 'd') {
	    node.data = 'dc';
	    if ($("#"+node.id).hasClass("jstree-closed")) {
		tree.set_type(node, 'f-closed-cache');
	    } else {
		tree.set_type(node, 'f-open-cache');
	    }
	    if (node.parent === '#') {
		const origText = extract_folder_name(tree.get_text(node));
		const newText = "<span><i class='far fa-check-circle'></i>&nbsp;"+origText+"</span>";
		tree.rename_node(node, newText);
	    }
	} else {
	    node.data = 'fc'
	    tree.set_type(node, 'f-file-cache');
	}
    } else {
	if (node.data[0] === 'd') {
	    node.data = 'd';
	    if ($("#"+node.id).hasClass("jstree-closed")) {
		tree.set_type(node, 'f-closed');
	    } else {
		tree.set_type(node, 'f-open');
	    }
	    if (node.parent === '#') {
		const origText = extract_folder_name(tree.get_text(node));
		const newText = "<span><i class='fas fa-cog'></i>&nbsp;"+origText+"</span>";
		tree.rename_node(node, newText);
	    }
	} else {
	    node.data = 'f';
	    tree.set_type(node, 'f-file');
	}
    }
}

function updateParentCacheStatus(node, tree) {
    let p = tree.get_node(node.parent);
    if (p.id === '#') return;
    
    let cs = p.children;
    let allCached = true;
    cs.forEach(it => {
	let cn = tree.get_node(it);
	if (cn.data === 'f' || cn.data === 'd') {
	    allCached = false;
	    return;
	}
    });
    if (allCached) {
	updateNodeCacheStatus(p, tree, true);

	updateParentCacheStatus(p, tree);
    }	       
}

function updateCacheStatus(node, tree) {
    if (node.id === '#') return;
    
    let cs = node.children;
    let allCached = true;
    cs.forEach(it => {
	let cn = tree.get_node(it);
	if (cn.data === 'f' || cn.data === 'd') {
	    allCached = false;
	    return;
	}
    });
    if (allCached) {
	updateNodeCacheStatus(node, tree, true);

	let pn = tree.get_node(node.parent.id);
	updateCacheStatus(pn, tree);
    }	
}

window.onClearCache = async (fileName, tree, node) => {
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
    var queryObj = JSON.stringify({"fname": fileName, "user": user,"token":token});
    var prom = runQuery("POST", "https://www.deepfoss.com/local2/clearcache", queryObj, getFileWait, getFileDone);
    try {
	var txtResponse = await prom;
	let robj = JSON.parse(txtResponse);
	if ('status' in robj && robj['status'] === 'error') {
	    ShowDiagnostics("Server Error", robj['reason']);
	} else {
	    clearAllChildrenCacheStatus(node, tree);
	    clearParentCacheStatus(node, tree);
	}
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
	// $('#minfo-display').show();
	// $('#minfo-display').html("Server Error: &nbsp;["+JSON.stringify(err)+"]&nbsp;&nbsp;");
	// window.HandleError(err);
    }
    
};

function setJobProjectSummaryTablesToUnknown(projName) {
    const jtable = document.getElementById('job-table');
    // [0][1] status field already set
    // clear job-table and project-table fields
    jtable.rows[1].cells[0].innerHTML = projName;
    jtable.rows[1].cells[2].innerHTML = "expired";
    jtable.rows[1].cells[3].innerHTML = "Unkown";
    jtable.rows[1].cells[4].innerHTML = "Unkown";
}

window.numFilesClick = (e, category) => {
    const fs = e.target.title.substr(0, e.target.title.length-1);
    const files = fs.split(',');

    const table = document.getElementById("filesTableBody");
    var rowCount = table.rows.length;
    var tableHeaderRowCount = 0;
    for (var i = tableHeaderRowCount; i < rowCount; i++) {
	table.deleteRow(tableHeaderRowCount);
    }
    files.forEach( item => {
	let row = table.insertRow();
	let pos = item.indexOf("/");
	const proj_name = item.substr(0, pos);
	const file_path = item.substr(pos+1);
	let proj = row.insertCell(0);
	proj.innerHTML = proj_name;
	let fpath = row.insertCell(1);
	fpath.innerHTML = file_path;
    });
    $("#list-files-header").html("Files Impacted by "+category);
    $("#list-files-div").modal({
	backdrop: 'static',
	keyboard: false
    });
    return true;
}

window.numProjectsClick = (e, category) => {
    const fs = e.target.title.substr(0, e.target.title.length-1);
    const projects = fs.split(',');

    const table = document.getElementById("projectsTableBody");
    var rowCount = table.rows.length;
    var tableHeaderRowCount = 0;
    for (var i = tableHeaderRowCount; i < rowCount; i++) {
	table.deleteRow(tableHeaderRowCount);
    }
    projects.forEach( item => {
	let row = table.insertRow();
	let proj = row.insertCell(0);
	proj.innerHTML = item;
    });
    $("#list-projects-header").html("Projects Impacted by "+category);
    $("#list-projects-div").modal({
	backdrop: 'static',
	keyboard: false
    });
    return true;
}

window.staticRuleInfoClick = async (e, ruleKey) => {
    if (window.isBusy) {
	// window.ShowMsg("Busy processing");
	return;
    }
    const issue = e.target.innerHTML;
    
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");

    let queryObj = JSON.stringify({"user": user,"token": token, 'rulekey': ruleKey});
    let	prom = runQuery("POST", "https://www.deepfoss.com/local2/getstaticruleinfo", queryObj, getFileWait, getFileDone);
    try {
	let txtResponse = await prom;
	let obj = JSON.parse(txtResponse);
	if ('status' in obj && obj['status'] === 'error') {
	    ShowDiagnostics("Server Error", obj['reason']);
	} else {
	    $("#static-rule-info-header").html(issue);
	    // Regular expression to find anchor tags without target="_blank" attribute
	    $("#static-rule-info-estimate").html("<p><h5>Estimated remediation effort: "+obj['rule']['remFnBaseEffort']+"</h5></p>");
	    let htmlContent = obj['rule']['htmlDesc'];
	    var regex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"(?:[^>]*?)>/gi;
	    
	    // Replace href with href and target="_blank"
	    var replacedContent = htmlContent.replace(regex, '<a href="$1" target="_blank">');

	    $("#static-rule-info-details").html(replacedContent);
	    $("#static-rule-info-div").modal({
		backdrop: 'static',
		keyboard: false
	    });
	}
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
    }
    
    return true;
}

function updateProjectViewTable(item) {
    const proj_name = item['proj_name'];
    let num_files = "Total: "+item['num_files'];

    if ('proj_lang' in item) {
	let num_lang_files = 0;
	num_files += '<br/>';
	Object.keys(item['proj_lang']).forEach ( (lang, index) => {
	    num_files += lang;
	    num_files += '&nbsp;&nbsp;(';
	    num_files += item['proj_lang'][lang].toString();
	    num_files += ')<br/>';
	    num_lang_files += item['proj_lang'][lang];
	});
	if (num_lang_files < item['num_files']) {
	    num_files += 'Other&nbsp;&nbsp;(';
	    num_files += (item['num_files']-num_lang_files).toString();
	    num_files += ')';
	}
    }
    const num_lines = item['num_lines'];
    let scope = translate_legacy_scope(item['scope']);

    scope = scope.replace(/,/g, '<br/> ');
    const processing_t = item['job_result']['processing_time'].toFixed(4).toString();
    const job_finished_at = item['job_result']['job_finished_at'];
    const num_files_with_match = item['scan_summary']['num_files_with_match'];
    // const num_files_with_cve = item['scan_summary']['num_match_with_cve'];
    let num_files_with_cve;
    let num_files_with_cve_str = '';
    if ('files_w_cve_match' in item) {
	num_files_with_cve = item['files_w_cve_match'].length;

	let title_str = '';
	item['files_w_cve_match'].forEach( (item, index) => {
	    title_str += item + ",";
	});
	num_files_with_cve_str += "<a><u><span onclick=\"window.numFilesClick(event, 'CVE')\" title=\""+ title_str +"\">" +num_files_with_cve.toString()+"</span></u></a>";
    } else {
	num_files_with_cve = "?"; //item['scan_summary']['num_match_with_cve'];
	num_files_with_cve_str = num_files_with_cve;
    }
    
    let num_files_with_foss;
    let num_files_with_foss_str = '';
    if ('files_w_match' in item) {
	num_files_with_foss = item['files_w_match'].length;

	let title_str = '';
	item['files_w_match'].forEach( (item, index) => {
	    title_str += item + ",";
	});
	num_files_with_foss_str += "<a><u><span onclick=\"window.numFilesClick(event,'FOSS')\" title=\""+ title_str +"\">" +num_files_with_foss.toString()+"</span></u></a>";
    } else {
	num_files_with_foss = "?"; //item['scan_summary']['num_match_with_cve'];
	num_files_with_foss_str = num_files_with_foss;
    }
    
    const licenses = item['scan_summary']['licenses'];
    let lic_str = '';
    Object.keys(licenses).forEach ( (item, index) => {
	lic_str += item;
	lic_str += '&nbsp;&nbsp;(';
	lic_str += licenses[item].toString();
	lic_str += ')<br/>';
    });
    let static_str = '0';
    if ('num_static_issues' in item['scan_summary']) {
	static_str = item['scan_summary']['num_static_issues'];
    }
    window.allviewtable.row.add([proj_name,scope,num_files,num_lines,processing_t,job_finished_at,num_files_with_foss_str,num_files_with_cve_str,static_str,lic_str]);
}

function translate_legacy_scope(scope_str) {
    const full_scope = "composition,static,vul,custom";
    if (scope_str) {
	if (scope_str == 'all' || scope_str == 'both') {
	    return full_scope;
	} else if (scope_str == 'foss') {
	    return 'composition';
	} else {
	    return scope_str;
	}	    
    }
    return full_scope;
}

function updateAllProjPieCharts(robj, proj) {
    $('#tab1-tab-allview').addClass('active').attr('aria-selected', 'true');;
    $('#tab1-allview').addClass('show active');
    $('#tab2-tab-allview').removeClass('active').attr('aria-selected', 'false');
    $('#tab2-allview').removeClass('show active');

    const scope_v = translate_legacy_scope(robj['scope']).split(',');

    //const isStaticAnalysis = (robj['scope'] == 'static');
    //const isVulAnalysis = (robj['scope'] == 'vul');
    //const isStaticVulAnalsys = (robj['scope'] == 'static_vul');
    //const isCompositionAnalysis = (robj['scope'] == 'composition' || robj['scope'] == 'foss' || robj['scope'] == 'custom');
   
    const num_files = robj['file_pie'][0];
    const num_foss_files = robj['file_pie'][1];
    
    let proj_data = [];
    proj_data.push(['Type', 'Number of files']);
    if (!scope_v.includes('composition')) { //isStaticAnalysis || isVulAnalysis || isStaticVulAnalsys) {
	proj_data.push(['N/A', num_files]);
    } else {
	proj_data.push(['Non-FOSS', num_files-num_foss_files]);
	proj_data.push(['FOSS', num_foss_files]);
    }
    
    let lic_data = [];
    const lic_pie = robj['license_pie'];
    lic_data.push(['Type', 'Number of files']);
    if (!scope_v.includes('composition')) { //isStaticAnalysis || isVulAnalysis || isStaticVulAnalsys) {
	lic_data.push(['N/A', robj['file_pie'][0]]);
    } else {
	Object.keys(lic_pie).forEach ( (item, index) => {
	    lic_data.push([item, lic_pie[item]]);
	});
	if (lic_data.length === 1 && robj['file_pie'][0] > 0) {
	    lic_data.push(['None', robj['file_pie'][0]]);
	}
    }
    
    let lic_compliance_data = [];
    const lic_compliance_pie = robj['license_compliance_pie'];
    lic_compliance_data.push(['Type', 'Number of files']);
    if (!scope_v.includes('composition')) { //isStaticAnalysis || isVulAnalysis || isStaticVulAnalsys) {
	lic_compliance_data.push(['N/A', robj['file_pie'][0]]);
    } else {
	Object.keys(lic_compliance_pie).forEach ( (item, index) => {
	    lic_compliance_data.push([get_license_compliance_word(item), lic_compliance_pie[item]]);
	});
	
	if (lic_compliance_data.length === 1 && robj['file_pie'][0] > 0) {
	    lic_compliance_data.push(['None', robj['file_pie'][0]]);
	}
    }
    
    let cve_data = [];
    const cve_pie = robj['cve_pie'];
    let total_cve = 0;
    cve_data.push(['Type', 'Number of files']);
    if (!scope_v.includes('vul')) { //isStaticAnalysis) {
	cve_data.push(['N/A', robj['file_pie'][0]]);
    } else {
	Object.keys(cve_pie).forEach ( (item, index) => {
	    cve_data.push([item, cve_pie[item]]);
	    total_cve += 1;
	});
	
	if (cve_data.length === 1) {
	    cve_data.push(['None', robj['file_pie'][0]]);
	}
    }
    let cve_severity_data = [];
    const cve_severity_pie = robj['cve_severity_pie'];
    cve_severity_data.push(['Type', 'Number of files']);
    if (!scope_v.includes('vul')) { //isStaticAnalysis) {
	cve_severity_data.push(['N/A', robj['file_pie'][0]]);
    } else {
	Object.keys(cve_severity_pie).forEach ( (item, index) => {
	    cve_severity_data.push([item, cve_severity_pie[item]]);
	});
	
	if (cve_severity_data.length === 1) {
	    cve_severity_data.push(['HIGH', 0]);
	    cve_severity_data.push(['LOW', 0]);
	    cve_severity_data.push(['MEDIUM', 0]);
	    cve_severity_data.push(['None', robj['file_pie'][0]]);
	}
    }
    let static_data = [];
    const static_pie = robj['static_pie'];
    let total_static_issues = 0;
    static_data.push(['Type', 'Number of files']);
    if (!scope_v.includes('static')) { //isCompositionAnalysis || isVulAnalysis) {
	static_data.push(['N/A', robj['file_pie'][0]]);
	total_static_issues = 'N/A';
    } else {
	console.log(static_pie);
	Object.keys(static_pie).forEach ( (item, index) => {
	    static_data.push([item, static_pie[item]]);
	    total_static_issues += static_pie[item];
	});
	if (static_data.length == 1) { // if only has headers
	    static_data.push(['None',robj['file_pie'][0]]);
	}
    }
    // static_data.push(['None', robj['file_pie'][0]]);
    
    GoogleCharts.load(function () {
	drawChart(proj_data, lic_data, lic_compliance_data, cve_data, cve_severity_data, static_data, proj, total_static_issues, total_cve);
    });
    
    // Draw the chart and set the chart values
    function drawChart(proj_data, lic_data, lic_compliance_data, cve_data, cve_severity_data, static_data, proj, total_static_issues, total_cve) {
	var pdata = GoogleCharts.api.visualization.arrayToDataTable(proj_data);

	let textColor, backGround;
	
	if (ThemePrevSelected() === 'dark') {
	    textColor = '#FFFFFF'; // '#000000'; //'#FFFFFF';
	    backGround = '#242424'; // '#FFFFFF'; //'#343a40';
	} else {
	    textColor = '#000000'; // '#000000'; //'#FFFFFF';
	    backGround = '#f6f6f6'; //'#FFFFFF'; // '#FFFFFF'; //'#343a40';
	}
	// Optional; add a title and set the width and height of the chart
	let foss_title = 'FOSS';
	if (proj === '__all__') {
	    foss_title = "All Projects View  "+foss_title;
	}
	var options = {pieSliceText: 'value-and-percentage', 'title':foss_title, 'height':200, backgroundColor: backGround, color: textColor, legendTextStyle: { color: textColor }, titleTextStyle: { color: textColor}, is3D: true};
	
	// Display the chart inside the <div> element with id="piechart"
	var chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('foss_piechart'));
	chart.draw(pdata, options);
	
	var ldata = GoogleCharts.api.visualization.arrayToDataTable(lic_data);
	
	options = {pieSliceText: 'value-and-percentage', 'title':'LICENSES',  'height':200, backgroundColor: backGround, color: textColor, legendTextStyle: { color: textColor }, titleTextStyle: { color: textColor}, sliceVisibilityThreshold: 0, is3D: true};
	
	chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('license_piechart'));
	chart.draw(ldata, options);

	var lcdata = GoogleCharts.api.visualization.arrayToDataTable(lic_compliance_data);

	// compliant, non-compliant, partial compliant, undefined
	options = {pieSliceText: 'value-and-percentage', 'title':'LICENSE COMPLIANCE',  'height':200, backgroundColor: backGround, color: textColor, legendTextStyle: { color: textColor }, titleTextStyle: { color: textColor}, colors: ['#00ff00', '#ff0000', '#ffff00', 'gray'], sliceVisibilityThreshold: 0, is3D: true};
	
	chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('license_compliance_piechart'));
	chart.draw(lcdata, options);
	
	var cdata = GoogleCharts.api.visualization.arrayToDataTable(cve_data);
	
	options = {pieSliceText: 'value-and-percentage', 'title':'CVE (TOTAL: '+total_cve+')',  'height':200, backgroundColor: backGround, color: textColor, legendTextStyle: { color: textColor }, titleTextStyle: { color: textColor}, is3D: true};
	chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('cve_piechart'));
	chart.draw(cdata, options);

	var csdata = GoogleCharts.api.visualization.arrayToDataTable(cve_severity_data);
	
	options = {pieSliceText: 'value-and-percentage', 'title':'CVE SEVERITY',  'height':200, backgroundColor: backGround, color: textColor, legendTextStyle: { color: textColor }, titleTextStyle: { color: textColor}, colors: ['#ff0000', '#ffff00', '#ffa500', '#00ff00'], is3D: true};
	chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('cve_severity_piechart'));
	chart.draw(csdata, options);

	var sdata = GoogleCharts.api.visualization.arrayToDataTable(static_data);
	
	options = {pieSliceText: 'value-and-percentage', 'title':'STATIC ANALYSIS ISSUES (TOTAL: '+total_static_issues+')',  'height':200, backgroundColor: backGround, color: textColor, legendTextStyle: { color: textColor }, titleTextStyle: { color: textColor}, colors: ['#ff0000', '#ffff00', '#ffa500', '#00ff00'], is3D: true};
	chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('static_piechart'));
	chart.draw(sdata, options);
    
    }
}
    
window.onProjSelected = async (e, projName) => {
    const startTime = new Date();
    $("#matchedfiles").parents('div.dataTables_wrapper').first().hide();
    $("#all-view-container").show();
    $("#component-view-container").hide();
    $("#license-view-container").hide();
    $("#static-result-container").hide();
    $("#cve-view-container").hide();
    $("#all-view-container").hide();
    // $("#project-info-container").show();
 
    let obj;
    let useCache = false;
    if (window.sessionStorage.getItem(projName)) {
	obj = JSON.parse(window.sessionStorage.getItem(projName));
	useCache = true;
    }
    console.log("useCache: "+useCache);
    
    if (!useCache) {
	let user = window.sessionStorage.getItem("cached_user");
	let token = window.sessionStorage.getItem("cached_token");
	let queryObj = JSON.stringify({"projectname": projName, "user": user, "token": token});
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/checkproject", queryObj, getFileWait, getFileDone);
	try {
	    let txtResponse = await prom;
	    // console.log(txtResponse);
	    obj = JSON.parse(txtResponse);
	    if ('status' in obj && obj['status'] === 'error') {
		if ('reason' in obj) {
		    throw obj['reason'];
		} else {
		    throw "unknown server error";
		}
	    }
	    
	} catch (err) {
	    console.log(err);
	    // ShowDiagnostics("Error", JSON.stringify(err));
	}
    }

    if (!obj) return;
    
    if ('status' in obj && obj['status'] === 'error') {
	// ShowDiagnostics("Server Error", obj['reason']);
	setJobProjectSummaryTablesToUnknown(projName);
	window.allviewtable.clear();
	window.allviewtable.draw();
    } else {
	if (obj['status'] === 'finished') {
	    const tree = $('#flist').jstree();
	    const node = tree.get_selected(true)[0];
	    setNodeAndAllChildrenCacheStatus(node, tree, true);
	    if ($("#project-info-container").is(':visible')) {
		$("#project-info-container").hide();
	    }
	} else if (obj['status'] === 'started') {
	    const tree = $('#flist').jstree();
	    const node = tree.get_selected(true)[0];
	    updateProjNodeToProcessing(node, tree);
	    if (!$("#project-info-container").is(':visible')) {
		$("#project-info-container").show();
	    }
	}
	
	const jtable = document.getElementById('job-table');
	jtable.rows[1].cells[0].innerHTML = projName;
	let scope = 'composition';
	if ('scope' in obj) {
	    scope = obj['scope'];
	}
	jtable.rows[1].cells[1].innerHTML = scope;
	jtable.rows[1].cells[2].innerHTML = obj['status'];
	jtable.rows[1].cells[5].innerHTML = obj['num_files'].toString()
	jtable.rows[1].cells[6].innerHTML = obj['num_lines'].toString();
	
	if (obj['status'] === 'finished') {
	    
	    if ('errormessage' in obj['job_result']) {
		ShowDiagnostics("Server Error", obj['job_result']['errormessage']);
		jtable.rows[1].cells[4].innerHTML = "N/A";
		jtable.rows[1].cells[5].innerHTML = "N/A";
		
	    } else if ('processing_time' in obj['job_result']) {
		jtable.rows[1].cells[3].innerHTML = "N/A";
		jtable.rows[0].cells[4].innerHTML = "PROCESSING TIME (second):";
		let process_time = obj['job_result']['processing_time']
		jtable.rows[1].cells[4].innerHTML = process_time.toFixed(4).toString();

		$("#all-view-container").show();

		window.allviewtable.clear();
		updateProjectViewTable(obj);
		window.allviewtable.draw();

		updateAllProjPieCharts(obj, projName);

		$("#all-project-view-label").html(projName);
		
		// pywindow.sessionStorage.setItem(projName, JSON.stringify(obj));

	    }
	    // job position
	    jtable.rows[1].cells[3].innerHTML = "N/A";
	} else {
	    
	    let pos_str = obj['position'].toString();
	    jtable.rows[1].cells[3].innerHTML = pos_str;
	    jtable.rows[0].cells[4].innerHTML = "ESTIMATED WAIT TIME (second):";
	    let estimated_finish_time = obj['time2finish'];
	    jtable.rows[1].cells[4].innerHTML = estimated_finish_time.toFixed(4).toString();
	    window.allviewtable.clear();
	    window.allviewtable.draw();

	}
    }
    const endTime = new Date();
    var timeDiff = endTime - startTime; //in ms
    timeDiff /= 1000;
    
    // get seconds 
    // var seconds = Math.round(timeDiff);
    console.log(timeDiff + " seconds in onProjSelected");
};

function get_cve_severity_color(cve_level) {
    if (cve_level === 'HIGH') {
	return 'red';
    } else if (cve_level === 'MEDIUM') {
	return 'orange';
    } else if (cve_level === 'LOW') {
	return 'yellow';
    } else if (cve_level === 'CRITICAL') {
	return 'purple';
    } else {
	return 'gray';
    }
}

function get_license_compliance_word(lic_compliance) {
    if (lic_compliance === 'compliant') {
	return 'COMPLIANT';
    } else if (lic_compliance === 'not compliant') {
	return 'NOT COMPLIANT';
    } else if (lic_compliance === 'partial compliant') {
	return 'PARTIAL COMPLIANT';
    } else {
	return 'UNDEFINED';
    }
}

function get_license_compliance_color(lic_compliance) {
    if (lic_compliance === 'compliant') {
	return 'green';
    } else if (lic_compliance === 'not compliant') {
	return 'red';
    } else if (lic_compliance === 'partial compliant') {
	return 'yellow';
    } else {
	return 'gray';
    }
}

function update_editor_comparison(src_fn, src_c, dst_fn, dst_c, region_match) {
    
    //editor.getModel().setValue(src_c);
    //meditor.getModel().setValue(dst_c);
    setSrcEditorText(src_c);
    setMatchEditorText(dst_c);
    
    if (window.sDecorations) {
	editor.deltaDecorations(window.sDecorations, []);
    }
    
    if (window.dDecorations) {
	meditor.deltaDecorations(window.dDecorations, []);
    }
    
    let srcDecorSettings = [];
    let dstDecorSettings = [];
	   
    region_match.forEach((item, index) => {
	let slineDecor = 'myLineDecoration';
	let sdWidth = 40;
	   
	let sSetting = {range: new monaco.Range(item['src'][0]+1, sdWidth, item['src'][1]+1, sdWidth), options: {isWholeLine: true, linesDecorationsClassName: slineDecor}};
	srcDecorSettings.push(sSetting);
	
	let mSetting = {range: new monaco.Range(item['dst'][0]+1, sdWidth, item['dst'][1]+1, sdWidth), options: {isWholeLine: true, linesDecorationsClassName: slineDecor}};
	dstDecorSettings.push(mSetting);
    });

     // set current match to 1st
    if (region_match.length > 0) {
	let item = region_match[0];
	let aSetting = {range: new monaco.Range(item['src'][0]+1,
						1,
						item['src'][1]+1,
						150),
			options: {isWholeLine: true,
				  linesDecorationsClassName: 'myLineDecoration_c'}};
	srcDecorSettings.push(aSetting);
	
	aSetting = {range: new monaco.Range(item['dst'][0]+1,
					    1,
					    item['dst'][1]+1,
					    150),
		    options: {isWholeLine: true,
			      linesDecorationsClassName: 'myLineDecoration_c'}};
	dstDecorSettings.push(aSetting);
    }
	    
    window.sDecorations = editor.deltaDecorations([],srcDecorSettings);
    window.dDecorations = meditor.deltaDecorations([],dstDecorSettings);
    if (region_match) {
	let item = region_match[0];
	if (item && item['src'] && item['dst']) {
	    editor.revealLineInCenter(item['src'][0]+1);
	    meditor.revealLineInCenter(item['dst'][0]+1);
	    MatchNavigator.SetMatchVec(region_match);
	}
    }

    let lang = getLangFromFileExt(dst_fn);
    
    let mmodel = meditor.getModel();
    monaco.editor.setModelLanguage(mmodel, lang);

    lang = getLangFromFileExt(src_fn);
    mmodel = editor.getModel();
    monaco.editor.setModelLanguage(mmodel, lang);
}

window.onViewComponents = async (projName) => {
    
    document.getElementById("ViewControl").innerHTML = '<i class="fa"></i> View All (Components) <i class="fa fa-caret-down"></i>';

    const user = window.sessionStorage.getItem("cached_user");
    const token = window.sessionStorage.getItem("cached_token");
    let queryObj;
    if (projName !== '__all__') {
	queryObj = JSON.stringify({"user": user, "token": token, "projectname": projName});
	$("#components-view-label").html(projName);
    } else {
	queryObj = JSON.stringify({"user": user, "token": token});
	$("#components-view-label").html("All Projects");
    }
    
    try {
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/getcomponentview", queryObj, getFileWait, getFileDone);
	let txtResponse = await prom;
	let robj = JSON.parse(txtResponse);
	
	if ('status' in robj && robj['status'] === 'error') {
	    if ('reason' in robj) {
		throw robj['reason'];
	    } else {
		throw "unknown server error";
	    }
	}

	var pkgdep_v = [];
	
	if ('comp' in robj) {
	    pkgdep_v = robj['pkgdep'];
	    console.log(pkgdep_v);
	    robj = robj['comp'];
	}
	$("#matchedfiles").parents('div.dataTables_wrapper').first().hide();
	$("#project-info-container").hide();
	$("#all-view-container").hide();
	
	//$("#all-view-chart-container").hide();
	//$("#allprojectsview").parents('div.dataTables_wrapper').first().hide();

	$("#license-view-container").hide();
	$("#static-result-container").hide();
	$("#cve-view-container").hide();
	
	$("#component-view-container").show();

	window.componentviewtable.clear();
	Object.keys(robj).forEach((item, index) => {
	    const row_it = robj[item];
	    let ver_str = '';
	    row_it['versions'].forEach((v, vidx) => {
		ver_str += v;
		ver_str += ",&nbsp;";
	    });
	    ver_str = ver_str.substr(0, ver_str.length-7);
	    let cve_str = '';
	    const cve_it = row_it['cve'];
	    Object.keys(cve_it).forEach((c, cidx) => {
		cve_str += to_text_cve_link(c)+"&nbsp;("+cve_it[c]+"),&nbsp;"
	    });
	    cve_str = cve_str.substr(0, cve_str.length-7);
	    let homePage;
	    let githubLink = "https://github.com/";
	    if (row_it['homepage'] !== 'null' && row_it['homepage'].trim() !== '') {
		homePage = to_text_link(row_it['homepage']);
	    } else {
		homePage = to_text_link(githubLink+item);
	    }
	    let num_proj_files_str = '';
	    let title_str = '';
	    row_it['proj_files'].forEach((f_it, f_it_index) => {
		title_str += f_it+",";
	    });
	    num_proj_files_str += "<a><u><span onclick=\"window.numFilesClick(event, '"+item+"')\" title=\""+ title_str +"\">" +row_it['proj_files'].length.toString()+"</span></u></a>";
	    let num_projs_str = '';
	    let proj_title_str = '';
	    row_it['projs'].forEach((p_it, p_it_index) => {
		proj_title_str += p_it+",";
	    });
	    num_projs_str += "<a><u><span onclick=\"window.numProjectsClick(event, '"+item+"')\" title=\""+ proj_title_str +"\">" +row_it['projs'].length.toString()+"</span></u></a>";
	    const pos = row_it['source'].indexOf('_');
	    let src_str = row_it['source'];
	    if (pos !== -1) {
		src_str = src_str.substr(0, pos);
	    }
	    const color_class = get_license_compliance_color(row_it['compliance']);
	    const compliance_str = "<div><div class='box "+color_class+"'></div>"+get_license_compliance_word(row_it['compliance'])+"</div>";

	    let repos_disp_str = item;
	    if (src_str !== 'private') {
		repos_disp_str = to_text_repos_link(item);
		if (cve_str) {
		    repos_disp_str += "&nbsp;&nbsp;"+to_check_component_link(item);
		}
	    }
	    window.componentviewtable.row.add([repos_disp_str,
					       row_it['score'],
					       ver_str,
					       row_it['license'],
					       compliance_str, //row_it['compliance'],
					       cve_str,
					       num_proj_files_str,
					       num_projs_str,
					       homePage,
					       src_str
					      ]);
	});
	window.componentviewtable.columns.adjust().draw();

	window.dependencyviewtable.clear();
	console.log(pkgdep_v);

	Object.keys(pkgdep_v).forEach((proj, proj_index) => {
	    const pkgdep = pkgdep_v[proj];
	    Object.keys(pkgdep).forEach((item, index) => {
		const row_it = pkgdep[item];
		const manifest = proj_manifest_links(row_it['proj_manifest']);
		let cve = '';
		row_it['cve'].forEach((cve_it,cve_index) => {
		    if (cve !== '') {
			cve += ', ';
		    }
		    cve += to_text_cve_link(cve_it);
		});
		
		const vul_v = row_it['pkg_vul'];
		let vul_str = '';
		vul_v.forEach((vit, vit_index) => {
		    var patch_str = vit['aff_pkg']['patch_purl'];
		    if (vit['aff_pkg']['patch_purl'] === '') {
			patch_str = 'null';
		    }
		    let vul_id;
		    if (vit['vul_id'].startsWith('CVE-')) {
			vul_id = to_text_cve_link(vit['vul_id']);
		    } else {
			vul_id = vit['vul_id'];
		    }
		    vul_str += '<p>'+vul_id+', vulnerable: <u>'+vit['aff_pkg']['vul_purl']+'</u>, patch: <u>'+patch_str+'</u></p>';
		});
		const provenance = row_it['provenance'];
		window.dependencyviewtable.row.add([item,
						    manifest,
						    cve,
						    vul_str,
						    provenance]);
	    });
	});
	window.dependencyviewtable.columns.adjust().draw();

    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
	return false;
    }
};

window.onViewCVEs = async (projName) => {
    document.getElementById("ViewControl").innerHTML = '<i class="fa"></i> View All (Vulnerabilities) <i class="fa fa-caret-down"></i>';
    
    const user = window.sessionStorage.getItem("cached_user");
    const token = window.sessionStorage.getItem("cached_token");
    let queryObj;
    if (projName !== '__all__') {
	queryObj = JSON.stringify({"user": user, "token": token, "projectname": projName});
	$("#cve-view-label").html(projName);
	$("#vul-view-label").html(projName);
    } else {
	queryObj = JSON.stringify({"user": user, "token": token});
	$("#cve-view-label").html("All Projects");
    }
    
    try {
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/getcveview", queryObj, getFileWait, getFileDone);
	let txtResponse = await prom;
	let robj = JSON.parse(txtResponse);
	
	if ('status' in robj && robj['status'] === 'error') {
	    if ('reason' in robj) {
		throw robj['reason'];
	    } else {
		throw "unknown server error";
	    }
	}
	
	$("#matchedfiles").parents('div.dataTables_wrapper').first().hide();
	$("#project-info-container").hide();

	$("#all-view-container").hide();
	
	//$("#all-view-chart-container").hide();
	//$("#allprojectsview").parents('div.dataTables_wrapper').first().hide();

	$("#component-view-container").hide();
	$("#license-view-container").hide();

	$("#static-result-container").hide();
	$("#cve-view-container").show();

	window.cveviewtable.clear();
	Object.keys(robj['vul_snippet_cve']).forEach((item, index) => {
	    const row_it = robj['vul_snippet_cve'][item];
	    let num_proj_files_str = '';
	    let title_str = '';
	    row_it['proj_files'].forEach((f_it, f_it_index) => {
		title_str += f_it+",";
	    });
	    num_proj_files_str += "<a><u><span onclick=\"window.numFilesClick(event, '"+item+"')\" title=\""+ title_str +"\">" +row_it['proj_files'].length.toString()+"</span></u></a>";
	    let num_projs_str = '';
	    let proj_title_str = '';
	    row_it['projs'].forEach((p_it, p_it_index) => {
		proj_title_str += p_it+",";
	    });
	    num_projs_str += "<a><u><span onclick=\"window.numProjectsClick(event, '"+item+"')\" title=\""+ proj_title_str +"\">" +row_it['projs'].length.toString()+"</span></u></a>";

	    const color_class = get_cve_severity_color(row_it['severity']);
	    const severity_str = "<div><div class='box "+color_class+"'></div>"+row_it['severity']+"</div>";
	    
	    const impact_type_color_class = get_cve_severity_color('HIGH');
	    const impact_type_str = "<div><div class='box "+impact_type_color_class+"'></div>1. Vulnerability snippet match</div>";
	    window.cveviewtable.row.add([all_cve_links(item),
					 severity_str,
					 num_proj_files_str,
					 num_projs_str,
					 impact_type_str
					]);
	});
	
	Object.keys(robj['pkg_dep_cve']).forEach((item, index) => {
	    const row_it = robj['pkg_dep_cve'][item];
	    let num_proj_files_str = '';
	    let title_str = '';
	    let num_impact_files = 0;
	    row_it['proj_files'].forEach((f_it, f_it_index) => {
		f_it['pkg_manifest'].forEach((pkg_m_it, pkg_m_index) => {
		    num_impact_files += 1;
		    title_str += pkg_m_it+' ('+f_it['pkg']+"),";
		});
	    });
	    num_proj_files_str += "<a><u><span onclick=\"window.numFilesClick(event, '"+item+"')\" title=\""+ title_str +"\">" +num_impact_files.toString()+"</span></u></a>";
	    let num_projs_str = '';
	    let proj_title_str = '';
	    row_it['projs'].forEach((p_it, p_it_index) => {
		proj_title_str += p_it+",";
	    });
	    num_projs_str += "<a><u><span onclick=\"window.numProjectsClick(event, '"+item+"')\" title=\""+ proj_title_str +"\">" +row_it['projs'].length.toString()+"</span></u></a>";

	    const color_class = get_cve_severity_color(row_it['severity']);
	    const severity_str = "<div><div class='box "+color_class+"'></div>"+row_it['severity']+"</div>";
	    const impact_type_color_class = get_cve_severity_color('MEDIUM');
	    const impact_type_str = "<div><div class='box "+impact_type_color_class+"'></div>2. Package dependency</div>";
	    
	    window.cveviewtable.row.add([all_cve_links(item),
					 severity_str,
					 num_proj_files_str,
					 num_projs_str,
					 impact_type_str
					]);
	});


	Object.keys(robj['any_snippet_cve']).forEach((item, index) => {
	    const row_it = robj['any_snippet_cve'][item];
	    let num_proj_files_str = '';
	    let title_str = '';
	    row_it['proj_files'].forEach((f_it, f_it_index) => {
		title_str += f_it+",";
	    });
	    num_proj_files_str += "<a><u><span onclick=\"window.numFilesClick(event, '"+item+"')\" title=\""+ title_str +"\">" +row_it['proj_files'].length.toString()+"</span></u></a>";
	    let num_projs_str = '';
	    let proj_title_str = '';
	    row_it['projs'].forEach((p_it, p_it_index) => {
		proj_title_str += p_it+",";
	    });
	    num_projs_str += "<a><u><span onclick=\"window.numProjectsClick(event, '"+item+"')\" title=\""+ proj_title_str +"\">" +row_it['projs'].length.toString()+"</span></u></a>";

	    const color_class = get_cve_severity_color(row_it['severity']);
	    const severity_str = "<div><div class='box "+color_class+"'></div>"+row_it['severity']+"</div>";
	    const impact_type_color_class = get_cve_severity_color('LOW');
	    const impact_type_str = "<div><div class='box "+impact_type_color_class+"'></div>3. Generic snippet match</div>";
	    window.cveviewtable.row.add([all_cve_links(item),
					 severity_str,
					 num_proj_files_str,
					 num_projs_str,
					 impact_type_str
					]);
	});
	window.cveviewtable.draw();

	window.vulviewtable.clear();
	
	if ('vul' in robj) {
	    Object.keys(robj['vul']).forEach((item, index) => {
		const row_it = robj['vul'][item];
		//console.log(item);
		//console.log(row_it);
		Object.keys(row_it).forEach((cit, cindex) => {
		    const citems = cit.split("__//__");
		    const cve_id = citems[0];
		    const repos = citems[1]+"/"+citems[2];
		    const commit = citems[3];
		    const fpath  = citems[4];
		    let fpath_sha = '';
		    if (citems.length == 6) {
			fpath_sha = citems[5];
		    }
		    const region_v = row_it[cit];
		    
		    window.vulviewtable.row.add([vul_match_link(item, region_v),
						 to_text_cve_link(cve_id),
						 to_text_repos_link(repos),
						 to_text_github_commit_link(commit, repos, fpath_sha),
						 vul_match_link(fpath, region_v),
						 vul_fix_link(fpath, region_v)]);
		});
	    });
	    window.vulviewtable.draw();
	}
	
	
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
	return false;
    }
}; //window.onViewCVEs

window.onViewLicenses = async (projName) => {
    document.getElementById("ViewControl").innerHTML = '<i class="fa"></i> View All (Licenses) <i class="fa fa-caret-down"></i>';
    
    const user = window.sessionStorage.getItem("cached_user");
    const token = window.sessionStorage.getItem("cached_token");
    let queryObj;
    if (projName !== '__all__') {
	queryObj = JSON.stringify({"user": user, "token": token, "projectname": projName});
	$("#licenses-view-label").html(projName);
    } else {
	queryObj = JSON.stringify({"user": user, "token": token});
	$("#licenses-view-label").html("All Projects");
    }
    
    try {
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/getlicenseview", queryObj, getFileWait, getFileDone);
	let txtResponse = await prom;
	let robj = JSON.parse(txtResponse);

	if ('status' in robj && robj['status'] === 'error') {
	    if ('reason' in robj) {
		throw robj['reason'];
	    } else {
		throw "unknown server error";
	    }
	}
	
	$("#matchedfiles").parents('div.dataTables_wrapper').first().hide();
	$("#project-info-container").hide();

	$("#all-view-container").hide();
	
	//$("#all-view-chart-container").hide();
	//$("#allprojectsview").parents('div.dataTables_wrapper').first().hide();

	$("#component-view-container").hide();
	$("#static-result-container").hide();
	$("#cve-view-container").hide();
	$("#license-view-container").show();

	window.licenseviewtable.clear();
	Object.keys(robj).forEach((item, index) => {
	    const row_it = robj[item];
	    let num_proj_files_str = '';
	    let title_str = '';
	    row_it['proj_files'].forEach((f_it, f_it_index) => {
		title_str += f_it+",";
	    });
	    let item_str = item.replace(/\"/g, "_");
	    num_proj_files_str += "<a><u><span onclick=\"window.numFilesClick(event, '"+item_str+"')\" title=\""+ title_str +"\">" +row_it['proj_files'].length.toString()+"</span></u></a>";
	    let num_projs_str = '';
	    let proj_title_str = '';
	    row_it['projs'].forEach((p_it, p_it_index) => {
		proj_title_str += p_it+",";
	    });
	    num_projs_str += "<a><u><span onclick=\"window.numProjectsClick(event, '"+item_str+"')\" title=\""+ proj_title_str +"\">" +row_it['projs'].length.toString()+"</span></u></a>";
	    const color_class = get_license_compliance_color(row_it['compliance']);
	    const compliance_str = "<div><div class='box "+color_class+"'></div>"+get_license_compliance_word(row_it['compliance'])+"</div>";
	    window.licenseviewtable.row.add([item,
					     compliance_str,
					     num_proj_files_str,
					     num_projs_str
					    ]);
	});
	window.licenseviewtable.draw();
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
	return false;
    }
}; // window.onViewLicenses

window.onViewStaticResult = async (projName) => {
    
    const user = window.sessionStorage.getItem("cached_user");
    const token = window.sessionStorage.getItem("cached_token");
    let queryObj;
    
    if (projName !== '__all__') {
	queryObj = JSON.stringify({"user": user, "token": token, "projectname": projName});
	$("#licenses-view-label").html(projName);
    } else {
	queryObj = JSON.stringify({"user": user, "token": token});
	$("#licenses-view-label").html("All Projects");
    }
    
    try {
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/getstaticanalysisview", queryObj, getFileWait, getFileDone);
	let txtResponse = await prom;
	let robj = JSON.parse(txtResponse);

	if ('status' in robj && robj['status'] === 'error') {
	    if ('reason' in robj) {
		throw robj['reason'];
	    } else {
		throw "unknown server error";
	    }
	}
	$("#matchedfiles").parents('div.dataTables_wrapper').first().hide();
	$("#project-info-container").hide();
	$("#all-view-container").hide();

	$("#component-view-container").hide();
	$("#cve-view-container").hide();
	$("#license-view-container").hide();

	$("#static-result-container").show();
	
	window.staticresulttable.clear();
	let noStaticAnalysisDone = true;
	let staticServiceUnavailable = false;
	Object.keys(robj).forEach((proj_name, index_p) => {
	    const proj_obj = robj[proj_name];
	    if ('issues' in proj_obj) {
		proj_obj['issues'].forEach((item, index) => {
		    let rule_str = "<a title=\"Click to view detailed explanations.\"><u><span onclick=\"window.staticRuleInfoClick(event, '"+item['rule']+"')\"\>" +escapeHtml(item['message'])+"</span></u></a>";
		    window.staticresulttable.row.add([
			proj_name,
			static_result_file_link(item),
			item['impacts'][0]['severity'],
			rule_str
		    ]);
		});
		noStaticAnalysisDone = false;
	    } else if ('status' in proj_obj) {
		staticServiceUnavailable = true;
	    }
	});
	window.staticresulttable.draw();
	if (noStaticAnalysisDone) {
	    $('#staticresult .dataTables_empty').html('Static analysis not performed');
	} else if (staticServiceUnavailable) {
	    $('#staticresult .dataTables_empty').html('Static analysis service was unavailable');
	}
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
	return false;
    }
}; // window.onViewStaticResult

window.onGetFile = async (e, table, fileName) => {
    if (window.isBusy) return;
    /*
    let table;
    const tableId = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id;
    if (tableId == 'dependencyview') {
	table = window.dependencyviewtable;
    } else if (tableId == 'staticresult') {
	table = window.staticresulttable;
    }
    */
    if (curRowIdx !== -1) {
	$(table.row(curRowIdx).nodes()).removeClass('selectedRow');
    }
    curRowIdx = table.cell(e.target.parentNode.parentNode.parentNode).index().row;

    $(table.row(curRowIdx).nodes()).addClass('selectedRow');

    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
    let queryObj = JSON.stringify({"filename": fileName, "user": user,'token':token});
    let prom = runQuery("POST", "https://www.deepfoss.com/local2/getfile", queryObj, getFileWait, getFileDone);
    try {
	let txtResponse = await prom;
	let obj = JSON.parse(txtResponse);
	if ('status' in obj && obj['status'] === 'error') {
	    ShowDiagnostics("Server Error", obj['reason']);
	} else {
	    let fContent = obj['file'];
	
	    // editor.getModel().setValue(fContent);
	    setSrcEditorText(fContent);
	    let lang = getLangFromFileExt(fileName);
	    let model = editor.getModel(); 
	    
	    monaco.editor.setModelLanguage(model, lang);
	    $('#src-info-display').html(fileName);
	    $('#minfo-display').html("");

	    if (table == window.staticresulttable) {
		const title_str = e.target.parentNode.parentNode.title;
		displayStaticResultsInEditor(title_str);
	    } 
	    showOnlySrcEditor();
	}
	
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
    }
};

window.onTreeFileSelected = async (e, fileName) => {
    if (window.isBusy) return;
    if (window.sessionStorage.getItem(fileName)) {
	const obj = JSON.parse(window.sessionStorage.getItem(fileName));
	
	console.log("getfile using cached obj");
	disp_cached_scan_result(obj, fileName, true);

	let fContent = obj['file'];
	setSrcEditorText(fContent);
	// editor.getModel().setValue(fContent);
	let lang = getLangFromFileExt(fileName);
	let model = editor.getModel(); 
	
	monaco.editor.setModelLanguage(model, lang);
	
	window.sessionStorage.setItem("cached_code_snippet", fContent);
	window.sessionStorage.setItem("cached_src_file", fileName)

	return;
    }
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
    let queryObj = JSON.stringify({"filename": fileName, "user": user,'token':token});
    let prom = runQuery("POST", "https://www.deepfoss.com/local2/getfile", queryObj, getFileWait, getFileDone);
    try {
	let txtResponse = await prom;
	let obj = JSON.parse(txtResponse);
	let fContent = obj['file'];

	setSrcEditorText(fContent);
	// editor.getModel().setValue(fContent);
	let lang = getLangFromFileExt(fileName);
	let model = editor.getModel(); 
	
	window.sessionStorage.setItem("cached_code_snippet", fContent);
	window.sessionStorage.setItem("cached_src_file", fileName)

	monaco.editor.setModelLanguage(model, lang);

	if ('status' in obj && obj['status'] === 'error') {
	    ShowDiagnostics("Server Error", obj['reason']);
	    
	} else if ('scan_result' in obj) {
	    disp_cached_scan_result(obj, fileName, true);
	    window.sessionStorage.setItem(fileName, JSON.stringify(obj));
	    
	} else {
	    $('#src-info-display').html(fileName+"*");
	    $('#minfo-display').html("");

	    $("#src-match-file").html("Scan results for <i>"+fileName+"</i>");
	    renderMatchFileTable([],[],false);

	    const node = $('#flist').jstree().get_selected(true)[0];
	    updateNodeCacheStatus(node, $('#flist').jstree(), false);
	    clearParentCacheStatus(node, $('#flist').jstree());
	}
	
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
    }
}

window.onVulMatchLinkClick = async (e, mt) => {
    // console.log(e.target.innerText);
    if (window.isBusy) {
	// window.ShowMsg("Busy processing");
	return;
    }
    if (curRowIdx !== -1) {
	$(window.vulviewtable.row(curRowIdx).nodes()).removeClass('selectedRow');
    }
    curRowIdx = window.vulviewtable.cell(e.target.parentNode.parentNode.parentNode).index().row;

    $(window.vulviewtable.row(curRowIdx).nodes()).addClass('selectedRow');

    const srcFilePath = e.target.parentNode.parentNode.parentNode.parentNode.children[0].innerText;
    const cveID = e.target.parentNode.parentNode.parentNode.parentNode.children[1].innerText;
    const repos = e.target.parentNode.parentNode.parentNode.parentNode.children[2].innerText.split('/');
    
    const commit = e.target.parentNode.parentNode.parentNode.parentNode.children[3].innerText;
    const matchFilePath = e.target.parentNode.parentNode.parentNode.parentNode.children[4].innerText;

    const region_v = str_to_array(e.target.title);
    
    // const region_v = str_to_array(e.target.parentNode.parentNode.parentNode.parentNode.children[4].title);
    
    const fixFilePath = e.target.parentNode.parentNode.parentNode.parentNode.children[5].innerText;

    
    const commitStr = [cveID,repos[0],repos[1],commit,matchFilePath].join("__//__");

    console.log(commitStr)
    
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");

    
    let queryObj = JSON.stringify({"fname": srcFilePath, "matched_commit": commitStr, "user": user,"token": token, "region_v": region_v});

    let prom;
    if (mt === 'fix') {
	prom = runQuery("POST", "https://www.deepfoss.com/local2/get_vul_fix", queryObj, getFileWait, getFileDone);
    } else {
	prom = runQuery("POST", "https://www.deepfoss.com/local2/match_vul", queryObj, getFileWait, getFileDone);
    }
    
    try {
	let txtResponse = await prom;
	let obj = JSON.parse(txtResponse);
	
	console.log(obj);

	showBothEditors();
	
	update_editor_comparison(obj['src_fn'], obj['src_c'],
				 obj['dst_fn'], obj['dst_c'],
				 obj['result']);

	
	$('#src-info-display').html(obj['src_fn']);
	
	$('#minfo-display').show();
	if (mt === 'fix') {
	    $('#minfo-display').html("&nbsp;[fix]&nbsp;&nbsp;"+e.target.innerText);
	} else {
	    $('#minfo-display').html("&nbsp;[match]&nbsp;&nbsp;"+matchFilePath);
	}
	
	
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
    }
    
    // console.log(repos);
}

window.onVulFixLinkClick = async (e) => {
    // console.log(e.target.innerText);
    if (window.isBusy) {
	// window.ShowMsg("Busy processing");
	return;
    }
    
}

window.onOpenCheckComponent = async (e) => {
    e.preventDefault();
    const title_str = e.target.parentNode.parentNode.parentNode.title;
    console.log(e.target.parentNode.parentNode.parentNode);
    
    const left = title_str.indexOf('[');
    const right = title_str.indexOf(']');
    const repos_name = title_str.substr(left+1, right-left-1);
    $('#component-path').val("github:"+repos_name);

    if (window.isBusy) {
	// window.ShowMsg("Busy processing");
	return;
    }

    $("#check-component-div").modal(
	{
	    backdrop: 'static',
	    keyboard: false
	}
    );
    $('#search-component-cve').click();
    return;
}

window.onFileLinkClick = async (e) => {
    // console.log(e.target.innerText);
    if (window.isBusy) {
	// window.ShowMsg("Busy processing");
	return;
    }

    if (curRowIdx !== -1) {
	$(window.dtable.row(curRowIdx).nodes()).removeClass('selectedRow');
    }
    curRowIdx = window.dtable.cell(e.target.parentNode.parentNode.parentNode).index().row;

    $(window.dtable.row(curRowIdx).nodes()).addClass('selectedRow');
    
    let orgName = e.target.parentNode.parentNode.parentNode.parentNode.children[0].innerText;
    let verStr = e.target.parentNode.parentNode.parentNode.parentNode.children[5].innerText;
    let filePath = orgName+"/"+verStr+"/"+e.target.innerText;
    const clen = e.target.parentNode.parentNode.parentNode.parentNode.children.length;
    let kb = e.target.parentNode.parentNode.parentNode.parentNode.children[clen-1].innerText;
    
    let srcTxt = editor.getModel().getValue();
    let fileName = window.sessionStorage.getItem("cached_src_file");
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
    
    let queryObj = JSON.stringify({"snippet": srcTxt, "mfile": filePath, "fname": fileName, "user": user,"token": token, 'groupIndex': kb});
    
    // XXX Need to handle updated snippet case, mismatch between searched result
    // and current snippet in the editor (not searched yet)
    let prom;
    if (kb === 'p') {
	prom = runQuery("POST", "https://www.deepfoss.com/custom2/customfilematch", queryObj, getFileWait, getFileDone);
    } else {
	prom = runQuery("POST", "https://www.deepfoss.com/local2/filematch", queryObj, getFileWait, getFileDone);
    }
    try {
	let txtResponse = await prom;
	let obj = JSON.parse(txtResponse);
	if ('status' in obj && obj['status'] === 'error') {
	    ShowDiagnostics("Server Error", obj['reason']);
	} else {
	    setMatchEditorText(obj['file']);
	    
	    if (window.sDecorations) {
		window.sDecorations = editor.deltaDecorations(window.sDecorations, []);
	    }

	    if (window.dDecorations) {
		window.dDecorations = meditor.deltaDecorations(window.dDecorations, []);
	    }
	    
	    
	    let snippetDecorSettings = [];
	    let matchDecorSettings = [];
	    
	    // set all matches
	    obj['match_result'].forEach( (item, index) => {
		console.log(item);
		
		let slineDecor = 'myLineDecoration';
		let sdWidth = 40;

		let sSetting = {range: new monaco.Range(item['src'][0]+1, sdWidth, item['src'][1]+1, sdWidth), options:
				{isWholeLine: true,
				 linesDecorationsClassName: slineDecor /*, className: 'highlighted-region'*/
				 
				}};

		// snippetDecorSettings.push(decoration);
		snippetDecorSettings.push(sSetting);
		let mSetting = {range: new monaco.Range(item['dst'][0]+1, sdWidth, item['dst'][1]+1, sdWidth), options: {isWholeLine: true, linesDecorationsClassName: slineDecor /* , className: 'highlighted-region' */ }};
		matchDecorSettings.push(mSetting);
	    });
	    
	    // set current match to 1st
	    if (obj['match_result'].length > 0) {
		let item = obj['match_result'][0];
		let aSetting = {range: new monaco.Range(item['src'][0]+1,
							1,
							item['src'][1]+1,
							150),
				options: {isWholeLine: true,
					  linesDecorationsClassName: 'myLineDecoration_c'}};
				
		
		snippetDecorSettings.push(aSetting);
		
		aSetting = {range: new monaco.Range(item['dst'][0]+1,
						    1,
						    item['dst'][1]+1,
						    150),
			    options: {isWholeLine: true,
				      linesDecorationsClassName: 'myLineDecoration_c'}};
		matchDecorSettings.push(aSetting);
	    }
	    
	    window.sDecorations = editor.deltaDecorations([],snippetDecorSettings);
	    window.dDecorations = meditor.deltaDecorations([],matchDecorSettings);
	    
	    //let pos = getHTMLPositionFromColumnOffset(8, 5, editor);
	    //console.log(pos);
	    //editor.addOverlayWidget(getOverlayWidget(pos['left'], pos['top']));
	    
	    if (obj['match_result']) {
		let item = obj['match_result'][0];
		if (item && item['src'] && item['dst']) {
		    editor.revealLineInCenter(item['src'][0]+1);
		    meditor.revealLineInCenter(item['dst'][0]+1);
		    MatchNavigator.SetMatchVec(obj['match_result']);
		}
	    }
	    let lang = getLangFromFileExt(filePath);
	    
	    let mmodel = meditor.getModel();
	    monaco.editor.setModelLanguage(mmodel, lang);
	    
	    $('#minfo-display').show();
	    $('#minfo-display').html("&nbsp;["+obj['match_percent']+"%]&nbsp;&nbsp;"+e.target.innerText);
	    showBothEditors();
	    
	} // else error status check
	
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
    }
}

function file_link(file_path, isCustom) {
    let idx;
    if (isCustom) {
	idx = -1;
    } else {
	idx = file_path.indexOf("/");
    }
    let fp = file_path.substr(idx+1, file_path.length-idx-1);
    // <img src='filediff.png' width=20px height=20px></img>
    return "<a title=\"Click to view side-by-side matches in the editor windows\"><u><span onclick=\"window.onFileLinkClick(event)\">"+fp+"</span></u></a>";
}

function get_title_str_from_textRange(textRange) {
    return textRange['startLine']+','
	+textRange['endLine']+','
	+textRange['startOffset']+','
	+textRange['endOffset']+',';
}

function escapeHtml(str) {
    var div = document.createElement('div');
    div.appendChild(document.createTextNode(str));
    return div.innerHTML.replace(/\"/g,'');
}

function static_result_file_link(item) {
    const file_path = item['filepath'];
    let title_str = '';
    if ('textRange' in item) {
	title_str += get_title_str_from_textRange(item['textRange']);
    } else {
	title_str += get_title_str_from_textRange({'startLine': 0,
						   'endLine': 0,
						   'startOffset': 0,
						   'endOffset': 0});
    }
    // title_str += '{{'+item['message'].replace(/\"/g,'')+'}}';
    title_str += '{{'+escapeHtml(item['message'])+'}}';

    if ('flows' in item) {
	title_str += ',';
	item['flows'].forEach((flow, index) => {
	    let loc_v = flow['locations'];
	    loc_v.forEach((loc, loc_index) => {
		title_str += get_title_str_from_textRange(loc['textRange']);
		title_str += '{{'+escapeHtml(loc['msg'])+'}}';
	    });
	});
    }
    console.log(title_str);
    
    return "<a title=\""+title_str+"\"><u><span onclick=\"window.onGetFile(event,window.staticresulttable,'"+file_path+"')\">"+file_path+"</span></u></a>"
}

function open_only_file_link(file_path, k) {
    return k.toString()+". <a title=\"Click to view the file content in the editor\"><u><span onclick=\"window.onGetFile(event,window.dependencyviewtable,'"+file_path+"')\">"+file_path+"</span></u></a>";
}

function proj_manifest_links(proj_manifest_str) {
    // console.log(proj_manifest_str);
    let vec = proj_manifest_str;
    let rs = "";
    for (let k=0; k<vec.length; k++) {
	rs += open_only_file_link(vec[k], k+1);
	rs += "<br>";
	// rs += ",&nbsp;";
    }
    return rs.substr(0, rs.length-4);
}

function array_to_str(region_v) {
    let region_v_str = '';
    region_v.forEach((it, index) => {
	region_v_str += it[0].toString()+' ';
	region_v_str += it[1].toString();
	if (index < region_v.length-1) {
	    region_v_str += ',';
	}
    });
    return region_v_str;
}

function str_to_array(region_v_str) {
    let region_v = [];
    const seg = region_v_str.split(',');

    console.log(seg);
    
    seg.forEach((it, index) => {
	const r = it.split(' ');
	console.log(r);
	region_v.push([parseInt(r[0]), parseInt(r[1])]);
    });
    return region_v;
}

function vul_match_link(file_path, region_v) {
    return "<a><u><span onclick=\"window.onVulMatchLinkClick(event,'match')\" title=\""+array_to_str(region_v) +"\">"+file_path+"</span></u></a>";
}

function vul_fix_link(file_path, region_v) {
    return "<a><u><span onclick=\"window.onVulMatchLinkClick(event,'fix')\" title=\""+array_to_str(region_v) +"\">"+file_path+"</span></u></a>";
}

function color_format_text(str, color) {
    let tstr = "<p style=\"color:"+color+"\">";
    tstr += str;
    tstr += "</p>";
    return tstr;
}

function get_serverload_color(load_level) {
    let color = '';
    if (load_level === 'high') {
	color = '#DC143C';
    } else if (load_level === 'medium') {
	color = '#FFA500';
    } else if (load_level === 'low') {
	color = '#228B22';
    }
    return color;
}

function formatedHits(hits_str) {
    let s_v = hits_str.split(',');
    if (s_v.length === 2) {
	return s_v;
    }
    return [hits_str, ''];
}

function renderMatchFileTable(mfVect, sfVect, hasScanned) {
    // document.getElementById("project-info-container").style.display = "none";
    $("#matchedfiles").parents('div.dataTables_wrapper').first().show();
    $("#project-info-container").hide();

    $("#all-view-container").hide();
    
    // $("#allprojectsview").parents('div.dataTables_wrapper').first().hide();

    $("#component-view-container").hide();
    $("#license-view-container").hide();
    $("#static-result-container").hide();
    
    $("#cve-view-container").hide();
    //$("#all-view-chart-container").hide();
   
    window.dtable.clear();
    
    let githubLink = "https://github.com/";
    mfVect.forEach(function(item) {
	let homePage = 'null';
	if (item['homepage'] !== 'null') {
	    homePage = to_text_link(item['homepage']);
	} else {
	    homePage = to_text_link(githubLink+item['author']+"/"+item['artifact']);
	}
	let stars;
	if ('score' in item) {
	    stars = item['score'];
	} else {
	    stars = 'Unknown';
	}
	const isCustom = (item['kbtype'] === 'customer');
	let src_str = '';
	const pos = item['source'].indexOf('_');
	if (pos !== -1) {
	    src_str = item['source'].substr(pos+1);
	}
	let s_v = formatedHits(item["hits"]);
	window.dtable.row.add([to_text_repos_link(item['author']+"/"+item['artifact']),
			       file_link(item['fpath'], isCustom),
			       "full (100%)",
			       s_v[0],
			       stars,
			       item['version'],
			       item['license'].toString(), item['published_at'].substr(0, 10),
			       all_cve_links(item['cve']),
			       homePage,
			       to_text_download_link(item['download_link']),
			       item['source'][0]+src_str]);
	//console.log(item);
    });
    sfVect.forEach(function(item) {
	let homePage = 'null';
	if (item['homepage'] !== 'null') {
	    homePage = to_text_link(item['homepage']);
	} else {
	    homePage = to_text_link(githubLink+item['author']+"/"+item['artifact']);
	}
	const isCustom = (item['kbtype'] === 'customer');

	// let s = item["hits"]; //.split(',');
	let stars;
	if ('score' in item) {
	    stars = item['score'];
	} else {
	    stars = 'Unknown';
	}
	let src_str = '';
	const pos = item['source'].indexOf('_');
	if (pos !== -1) {
	    src_str = item['source'].substr(pos+1);
	}
	let s_v = formatedHits(item["hits"]);
	let mt_str = "partial";
	//if (s_v[1]) {
	//    mt_str += " ("+s_v[1]+")";
	//}
	window.dtable.row.add([to_text_repos_link(item['author']+"/"+item['artifact']),
			       file_link(item['fpath'], isCustom),
			       mt_str,
			       s_v[0],
			       stars,
			       item['version'],
			       item['license'].toString(), item['published_at'].substr(0, 10),
			       all_cve_links(item['cve']),
			       homePage,
			       to_text_download_link(item['download_link']),
			       item['source'][0]+src_str]);
	//console.log(item);
	
    });
    
    if (mfVect.length === 0 && sfVect.length === 0) {
	//console.log("reset emptyTable message");
	//console.log(window.dtable.context[0].oLanguage);
	let emptyMsg;
	if (hasScanned) {
	    emptyMsg = "No matching files found";
	} else {
	    emptyMsg = "Scanning for this file has NOT been performed";
	}
	window.dtable.context[0].oLanguage.sEmptyTable = emptyMsg;
    }
    
    window.dtable.draw();
}

function getFileWait() {
    // $("#match-code-area").show();
    $("#busy-indicator").show();
    $("#scan-progress").hide();

    // disableLinks();
    
    window.isBusy = true;
}

function getFileDone() {
    $("#busy-indicator").hide();
    //enableLinks();
    window.isBusy = false;
}

function scanJobWait() {
    $("#busy-indicator").show();
    window.isBusy = true;
}

function scanJobDone() {
    $("#busy-indicator").hide();
    window.isBusy = false;
}

function format(d) {
    console.log(d);
    return (
	'<div class=\"details_row_table\">'+
        'Full name: ' +
        "coby" +
        ' ' +
        'xu' +
        '<br>' +
        'Salary: ' +
        '300' +
        '<br>' +
        'The child row can contain any data you wish, including links, images, inner tables etc.'+
	'</div>'
    );
}

const validateEmail = (email)  => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
}

function runQuery(method, url, queryObj, waitFcn, doneFcn, time_out = 300000) {
    return new Promise(function(resolve, reject) {
        let xhr = new XMLHttpRequest();

	xhr.timeout = time_out;
	
        xhr.open(method, url);
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 500) {
                resolve(xhr.response);
            } else {
		ShowDiagnostics("Server Error", JSON.stringify(xhr.statusText));
		
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
	    doneFcn();
        };
        xhr.onerror = function () {
	    ShowDiagnostics("Server Error", JSON.stringify(this.status)+" "+xhr.responseText);
	    
	    doneFcn();
	    
	    console.log("server error");
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
	
	xhr.ontimeout = function() {
	    ShowDiagnostics("Server Timeout Error", xhr.statusText);
	    
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
	};
        xhr.send(queryObj);
	waitFcn();

    });
}

async function updateSearchStatus() {
    let user = window.sessionStorage.getItem("cached_user");
    let token = window.sessionStorage.getItem("cached_token");
   
    const queryObj = JSON.stringify({"user": user, "token": token});
    let prom = runQuery("POST", "https://www.deepfoss.com/local2/codesearchprogress", queryObj, scanJobWait, scanJobDone);
    try {
	let txtResponse = await prom;
	let tobj = JSON.parse(txtResponse);
	if ('status' in tobj && tobj['status'] === 'error') {
	    clearInterval(progressIntervalVar);
	    ShowDiagnostics("Server Error", tobj['reason']);
	    $("#scan-progress").hide();
	    enableLinks();

	} else {
	    
	    let elapTime = -1;
	    if ('scan_result' in tobj) {
		clearInterval(progressIntervalVar);
		let obj = tobj['scan_result'];
		elapTime = obj['elapse_time'];
		let fn;
		Object.keys(obj).forEach ( (item, index) => {
		    if (item !== 'elapse_time') {
			fn = item;
		    }
		});
	    
		//'webservice_code.txt';
		//window.sessionStorage.setItem("cached_full_match", JSON.stringify(obj[fn]['full_match']));
		//window.sessionStorage.setItem("cached_snippet_match", JSON.stringify(obj[fn]['snippet_match']));
		renderMatchFileTable(obj[fn]['full_match'],obj[fn]['snippet_match'],true);
		
		// meditor.getModel().setValue('');
		setMatchEditorText('');
		if (window.sDecorations) {
		    editor.deltaDecorations(window.sDecorations, []);
		}
		MatchNavigator.HideButtons();

		enableLinks();

		let fnSrcInfoDisp = $('#src-info-display').html();
		let fnLen = fnSrcInfoDisp.length;
		if (fnSrcInfoDisp[fnLen-1] === '*') {
		    fnSrcInfoDisp = fnSrcInfoDisp.substr(0, fnLen-1);
		    $('#src-info-display').html(fnSrcInfoDisp);
		}

		let scope_str = '';
    
		if ('scope' in tobj) {
		    scope_str = "Search "+tobj['scope'];
		}
    
		$("#src-match-file").html(scope_str+"scan results for <i>"+fnSrcInfoDisp+"</i>");
		const node = $('#flist').jstree().get_selected(true)[0];
		if (node && !snippetSearch) {
		    updateNodeCacheStatus(node, $('#flist').jstree(), true);
		    updateParentCacheStatus(node, $('#flist').jstree());
		}
	    } 
	    let progstr = tobj['status'];
	    let cprog = parseInt(progstr.substr(0, progstr.length-1));
	    let ptxt;
	    if (cprog === 100) {
		ptxt = "Finished searching "+ window.numSrcTextLines.toString() +" lines through 500 billion lines";
		if (elapTime !== -1) {
		    ptxt += " in "+elapTime+" seconds"
		}
	    } else {
		ptxt = cprog + "% done. Searching "+ window.numSrcTextLines.toString() + " lines through 500 billion lines";
	    }

	    let minfo = "";
	    if ('server' in tobj && tobj['server'].length>0) {
		minfo += ",&nbsp; server done:";
		for (let tidx=0; tidx <tobj['server'].length; tidx++) {
		    minfo += "&nbsp;";
		    minfo += tobj['server'][tidx]
		}
	    }
	    minfo = tobj['status']+"&nbsp;"+minfo;
	    $("#scan-progress-bar")
		.css("width", cprog + "%")
		.attr("aria-valuenow", cprog);
	    $("#scan-progress-bar-disp").text(ptxt);
	    $('#minfo-display').show();

	    let minfostr = '';
	    if ('serverload' in tobj) {
		minfo += ",&nbsp; server load: \""+tobj['serverload']+"\""
		minfostr = color_format_text("&nbsp; ["+minfo+"] &nbsp;&nbsp;", get_serverload_color(tobj['serverload']));
	    } else {
		minfostr = "&nbsp; ["+minfo+"] search finished &nbsp;&nbsp;";
	    }
	    $('#minfo-display').html(minfostr);
	} // else status error check
    } catch (err) {
	console.log(err);
	ShowDiagnostics("Server Error", JSON.stringify(err));
    }
}

class App extends React.Component {
    constructor(props) {
	super(props);
	this.state = {tab: "main", signedinuser: "", signinstatus: "", token: "", user_t: ""};
    }

    onSignOut = async (e) => {
	if (this.state.signedinuser === '') {
	    return;
	}
	
	let infoObj = JSON.stringify({'user': this.state.signedinuser,'token': this.state.token});
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/logout", infoObj, loginWait, loginDone);
	try {
	    let txtResponse = await prom;
	    let obj = JSON.parse(txtResponse);

	    window.sessionStorage.removeItem("cached_user");
	    this.setState({signedinuser: "", signinstatus:"signed out", token:""});

	    if (obj['status'] === 'success') {
		console.log("log out successful");
	    } else {
		console.log("log out forced");
		// ShowDiagnostics("Server Error", obj['reason']);
	    }
	    // document.cookie = "signedin_user=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	    
	    // window.HandleError(err);
	}
	clearAllIntervalVars();
	$('#flist').jstree("destroy").empty();
    }
    
    onSignIn = async (e) => {
	e.preventDefault();
	let user = document.getElementById('loginuser').value;
	let valid = validateEmail(user);
	if (!valid) {
	    this.setState({signedinuser: "", signinstatus: "Invalid email address", token: ""});
	    return;
	}

	let pw = document.getElementById('loginpw').value;
	if (pw.length < 6) {
	    this.setState({signedinuser: "", signinstatus: "Invalid password. Password length must be at least 6.", token: ""});
	    return;
	}
	
	let infoObj = JSON.stringify({'user': user, 'passwd': pw});
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/login", infoObj, loginWait, loginDone);

	console.log("login: "+ user);
	
	try {
	    let txtResponse = await prom;
	    let obj = JSON.parse(txtResponse);
	    if (obj['status'] === 'success') {
		this.setState({signedinuser: user, signinstatus: obj['reason'], token: obj['token'], user_t: obj['user_t']});
		
		window.sessionStorage.setItem("cached_user", user);
		window.sessionStorage.setItem("cached_token", obj['token']);
		window.sessionStorage.setItem("cached_user_t", obj['user_t']);
		// document.cookie = "signedin_user="+user;
		
		clearAllIntervalVars();
		
		var now = new Date();
		// console.log("Logged in: Start inactive count down at "+now.toUTCString());
		window.inactiveLogoutIntervalVar = setInterval(inactiveLogout, inactiveTimeout); // 15 minute
		
	    } else {
		console.log("failed");
		this.setState({signedinuser: "", signinstatus: obj['reason'], token: ""});
	    }
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	
	    // window.HandleError(err);
	}
    }

    onSignUp = async (e) => {
	e.preventDefault();
	let user = document.getElementById('loginuser').value;
	let valid = validateEmail(user);
	
	if (!valid) {
	    this.setState({signedinuser: "", signinstatus: "Invalid email address", token: ""});
	    $("#register_link").click();
	    return;
	} else {
	    let pw = document.getElementById('loginpw').value;
	    if (pw.length < 6) {
		this.setState({signedinuser: "", signinstatus: "Invalid password. Password length must be at least 6.", token: ""});
		$("#register_link").click();
		return;
	    }
	    if ($("#confirmpassword_div").css("display") !== "none") {
		let confirmpw = document.getElementById('confirmloginpw').value;
		if (pw !== confirmpw) {
		    this.setState({signedinuser: "", signinstatus: "Password and Confirm Password must match.", token: ""});
		    $("#register_link").click();
		    return;
		}
	    }
	    
	    let infoObj = JSON.stringify({'user': user, 'passwd': pw, 'email': user});
	    console.log("signup: "+ user);
	
	    let prom = runQuery("POST", "https://www.deepfoss.com/local2/signup", infoObj, loginWait, loginDone);
	    try {
		let txtResponse = await prom;
		let obj = JSON.parse(txtResponse);
		if (obj['status'] === 'success') {
		    let signin_status;
		    if (obj['activate_status'] === 'pending') {
			alert(user + " has been successfully registered. You will receive an activation link in your email. You can log in after your account is activated.");
			signin_status = "Your registration is being processed. We will email you for account activation.";
		    } else {
			signin_status = "You can login now.";
			window.location.replace("https://www.deepfoss.com?useremail="+user);
		    }
		    this.setState({signedinuser: '', signinstatus: signin_status, token: ''});
		    // document.cookie = "signedin_user="+user;
		} else {
		    const pos = obj['reason'].indexOf("already registered");
		    if (pos === -1) {
			let signin_status = "You can login now.";
			let cur_url = window.location.href;
			const invite_pos = cur_url.indexOf("&invite=");
			if (invite_pos !== -1) {
			    let redirect_url = cur_url.substr(0, invite_pos);
			    window.location.replace(redirect_url);
			    this.setState({signedinuser: '', signinstatus: signin_status, token: ''});
			} else {
			    console.log("failed");
			    console.log(obj);
			    this.setState({signedinuser: "", signinstatus: obj['reason'], token: ""});
			}

		    } else {
			console.log("failed");
			console.log(obj);
			this.setState({signedinuser: "", signinstatus: obj['reason'], token: ""});
		    }
		}
	    } catch (err) {
		console.log(err);
		ShowDiagnostics("Server Error", JSON.stringify(err));
		
		// window.HandleError(err);
	    }
	}
    }
        
    onResetPassword = async (e) => {
	if (this.state.signedinuser === '') {
	    return;
	}
	const oldPasswd = $("#current-password").val();
	const newPasswd = $("#new-password").val();
	const verifyNewPasswd = $("#verify-new-password").val();
	if (newPasswd !== verifyNewPasswd) {
	    alert("'New password' does not match 'Verify new password'");
	    return;
	}
	let infoObj = JSON.stringify({'user': this.state.signedinuser,'token': this.state.token, 'oldpasswd': oldPasswd, 'newpasswd': newPasswd});
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/reset", infoObj, loginWait, loginDone);
	try {
	    let txtResponse = await prom;
	    let obj = JSON.parse(txtResponse);

	    if (obj['status'] === 'failed') {
		alert("Password reset failed: "+obj['reason']);
	    } else {
		$("#current-password").val('');
		$("#new-password").val('');
		$("#verify-new-password").val('');
		alert('Password reset successful.');
		$("#reset-password-div").modal('hide');
	    }
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	}
    }
    
    onDarkModeSwitch() {
	let curr = $('#dark_mode').html().indexOf("fa-sun");
	if (curr === -1) {
	    //ThemeSwitch('dark');
	    //$('#dark_mode').html("<i class=\"fas fa-sun\"></i>");
	    document.cookie = "theme_choice=dark";
	} else {
	    //ThemeSwitch('light');
	    //$('#dark_mode').html("<i class=\"fas fa-moon\"></i>");
	    document.cookie = "theme_choice=light";
	}
	window.location.reload();
    }
    
    onSubmitProject = async (e) => {
	e.preventDefault();
	$("#submit-project-div").modal(
	    
	);
	// console.log($("#submit-project-form")[0].multiple);
	return;
    }
    
    onIndex = async (e) => {
	e.preventDefault();
	$("#index-custom-div").modal(
	    {
		backdrop: 'static',
		keyboard: false
	    }
	);
	return;
    }

    onSelAllPKB = async (e) => {
	const table = document.getElementById("pkbTableBody");
	const rowCount = table.rows.length;
	for (var i = 0; i < rowCount; i++) {
	    const row = table.rows[i].cells[9];
	    row.children[0].checked = e.target.checked;
	}
    }

    onDeletePKB = async (e) => {
	e.preventDefault();

	if (window.isBusy) {
	    // window.ShowMsg("Busy processing");
	    return;
	}
	
	const table = document.getElementById("pkbTableBody");
	const rowCount = table.rows.length;
	var delList = [];

	for (var i = 0; i < rowCount; i++) {
	    const sel = table.rows[i].cells[9];
	    if (sel.children[0].checked) {
		const row = table.rows[i];
		const author = row.cells[0].innerHTML;
		const artifact = row.cells[1].innerHTML;
		const version = row.cells[2].innerHTML;
		const rec = {'author': author, 'artifact': artifact, 'version': version};
		delList.push(rec);
	    }
	}
	if (delList.length === 0) {
	    let emptyList = window.confirm("No repositories is selected");
	    return false;
	}
	
	const user = window.sessionStorage.getItem("cached_user");
	const token = window.sessionStorage.getItem("cached_token");
	const queryObj = JSON.stringify({"user": user, "token": token, "repos_info": delList});
	try {
	    let prom = runQuery("POST", "https://www.deepfoss.com/custom2/pkbdelete", queryObj, getFileWait, getFileDone);
	    let txtResponse = await prom;
	    let robj = JSON.parse(txtResponse);
	    if ('status' in robj && robj['status'] === 'error') {
		ShowDiagnostics("Server Error", robj['reason']);
		return false;
	    } else {
		
		const table = document.getElementById("pkbTableBody");
		i = 0;
		while (i < table.rows.length) {
		    const row = table.rows[i].cells[9];
		    if (row.children[0].checked) {
			table.deleteRow(i);
		    } else {
			i++;
		    }
		}
		$("#pkb-sel-all").prop("checked", false);
		$("#list-pkb-div").modal('hide');
		ShowDiagnostics("Deleting Status", robj['reason']);
		
		return true;
	    }
	    
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	    return false;
	} 
    }
    
    onListPKB = async (e) => {
	e.preventDefault();

	if (window.isBusy) {
	    // window.ShowMsg("Busy processing");
	    return;
	}
	
	const user = window.sessionStorage.getItem("cached_user");
	const token = window.sessionStorage.getItem("cached_token");
	const queryObj = JSON.stringify({"user": user, "token": token, "next":0, "size":-1});
	try {
	    let prom = runQuery("POST", "https://www.deepfoss.com/custom2/customList", queryObj, getFileWait, getFileDone);
	    
	    let txtResponse = await prom;
	    let robj = JSON.parse(txtResponse);
	    if ('status' in robj && robj['status'] === 'error') {
		ShowDiagnostics("Server Error", robj['reason']);
		return false;
	    } else {

		const table = document.getElementById("pkbTableBody");
		var rowCount = table.rows.length;
		var tableHeaderRowCount = 0;
		for (var i = tableHeaderRowCount; i < rowCount; i++) {
		    table.deleteRow(tableHeaderRowCount);
		}
		robj['results'].forEach( item => {
		    let row = table.insertRow();
		    let author = row.insertCell(0);
		    author.innerHTML = item['author'];
		    let repos = row.insertCell(1);
		    repos.innerHTML = item['artifact'];
		    let version = row.insertCell(2);
		    version.innerHTML = item['version'];
		    let desc = row.insertCell(3);
		    desc.innerHTML = item['description'];
		    let publish = row.insertCell(4);
		    publish.innerHTML = item['published_at'];
		    let lic = row.insertCell(5);
		    lic.innerHTML = item['license'].toString();
		    let score = row.insertCell(6);
		    score.innerHTML = item['score'];
		    let dn = row.insertCell(7);
		    dn.innerHTML = item['download_link'];
		    let numfiles = row.insertCell(8);
		    numfiles.innerHTML = item['totalfiles'];
		    let sel = row.insertCell(9);
		    sel.innerHTML = "<input type=\"checkbox\"> </input>";
		    
		});
		
		$("#list-pkb-div").modal({
		    backdrop: 'static',
		    keyboard: false
		});
		return true;
	    }
	    
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	    return false;
	}
    }

    onShowFileTreeOption = async (e) => {
	console.log(e);
	if ($('#filetreeoption').is(":checked")) {
	    document.cookie = "filetreeoption=show_match_only";
	} else {
	    document.cookie = "filetreeoption=show_all";
	}
	OpenProjFcn(this.state.signedinuser, this.state.token);
    }
    
    onCheckComponent = async (e) => {
	e.preventDefault();
	
	if (window.isBusy) {
	    // window.ShowMsg("Busy processing");
	    return;
	}

	$("#check-component-div").modal(
	    {
		backdrop: 'static',
		keyboard: false
	    }
	);
	return;
    }

    onComponentPathEnter = async (e) => {
	if(e.which == 13){//Enter key pressed
            $('#search-component-cve').click();
        }
    }

    onClickRescanProjectButton = async (e) => {
	e.preventDefault();
	
	if (window.isBusy) {
	    // window.ShowMsg("Busy processing");
	    return;
	}
	let user = window.sessionStorage.getItem("cached_user");
	let token = window.sessionStorage.getItem("cached_token");
	let projectName = $("#rescanprojectname").html();
    
	var checkboxes = document.querySelectorAll('input[name="rsanalysisscope"]');
	var checkedValues = [];
	checkboxes.forEach(function(checkbox) {
	    if (checkbox.checked) {
		checkedValues.push(checkbox.value);
	    }
	});
	const scope_str = checkedValues.join(',');
	//let scope = 'composition';
	
	//if (user_type !== 'f') {
	//	scope = DBScopePrevSelected();
	//}
	let queryObj = JSON.stringify({"projectname": projectName, "scope": scope_str, "user":user, "token":token});
	
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/rescanproject", queryObj, getFileWait, getFileDone);
	$("#rescan-project-div").modal('hide');
	
	try {
	    let txtResponse = await prom;
	    let robj = JSON.parse(txtResponse);
	    if ('status' in robj && robj['status'] === 'error') {
		// ShowDiagnostics("Error", robj['reason']);
		alert(robj['reason']);
		return false;
	    }
	    
	    processingScanProject(robj, false);
	    
	    const tree = $('#flist').jstree();
	    const node = tree.get_selected(true)[0];
	    
	    setNodeAndAllChildrenCacheStatus(node, tree, false);
	    updateNodeCacheStatus(node, tree, false);
	    
	    incrementURLBOMTimeStamp();
	    
	    if (window.sessionStorage.getItem(projectName)) {
		window.sessionStorage.removeItem(projectName);
	    }
	
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	}
    }
    
    onSearchComponentCVE = async (e) => {
	e.preventDefault();
	
	if (window.isBusy) {
	    // window.ShowMsg("Busy processing");
	    return;
	}
	const user = window.sessionStorage.getItem("cached_user");
	const token = window.sessionStorage.getItem("cached_token");
	const repospath = $('#component-path').val().replace(/\s/g,''); //trim();
	const queryObj = JSON.stringify({"user": user, "token": token, "repospath": repospath});
	try {
	    let prom = runQuery("POST", "https://www.deepfoss.com/local2/checkrepos", queryObj, getFileWait, getFileDone);
	    
	    let txtResponse = await prom;
	    let robj = JSON.parse(txtResponse);
	    if ('status' in robj && robj['status'] === 'error') {
		ShowDiagnostics("Error", robj['reason']);
		return false;
	    } else {
		if ('status' in robj && robj['status'] === 'not_found') {
		    $('#check-component-result').html("N/A");
		    $('#check-component-clean-releases').html("N/A");
		    
		    $('#checkComponentTable tbody').empty();
		} else {
		    const table = document.getElementById("checkComponentTableBody");
		    var rowCount = table.rows.length;
		    var tableHeaderRowCount = 0;
		    for (var i = tableHeaderRowCount; i < rowCount; i++) {
			table.deleteRow(tableHeaderRowCount);
		    }

		    console.log(robj);
		    
		    const cve_v = robj['cve_v']; //.split(",");
		    const cwe_v = robj['cwe_v']; //.split(",");
		    const impact_releases_v = robj['impact_releases']; //.split("==||||==");
		    const severity_v = robj['severity_v']; //.split(",");

		    console.log(severity_v);
		    
		    cve_v.forEach( (item, index) => {
			let row = table.insertRow();
			let cve_id = item;
			let cwe_id = cwe_v[index];
			let impact_releases = impact_releases_v[index];
			let severity = severity_v[index];
			let cveIt = row.insertCell(0);
			cveIt.innerHTML = to_text_cve_link_plain(cve_id);
			let cweIt = row.insertCell(1);
			const cwe_row_v = cwe_id.split(':');
			let cwe_row_str = [];
			cwe_row_v.forEach( (cwe_row_it, index) => {
			    cwe_row_str.push(to_text_cwe_link(cwe_row_it))
			});
			cweIt.innerHTML = cwe_row_str.join(",");
			let impactIt = row.insertCell(2);
			impactIt.innerHTML = impact_releases.split(",").join(", ");
			let severityIt = row.insertCell(3);
			severityIt.innerHTML = severity;
		    });
		    $('#check-component-result').html("&nbsp;&nbsp;<i>"+cve_v.length.toString()+"</i>");
		    let clean_rel_str = robj['clean_releases'].join(",&nbsp;");
		    if (robj['clean_releases'].length === 0) {
			clean_rel_str = "None";
		    }
		    $('#check-component-clean-releases').html("&nbsp;&nbsp;<i>"+clean_rel_str+"</i>");

		}
	    }
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	    return false;
	}
    }
    
    onAllView = async (e) => {
	document.getElementById("ViewControl").innerHTML = '<i class="fa"></i> View All (Projects) <i class="fa fa-caret-down"></i>';

	const user = this.state.signedinuser; //window.sessionStorage.getItem("cached_user");
	const token = this.state.token; //window.sessionStorage.getItem("cached_token");
	const queryObj = JSON.stringify({"user": user, "token": token});
	
	try {
	    let prom = runQuery("POST", "https://www.deepfoss.com/local2/getallprojectsview", queryObj, getFileWait, getFileDone);
	    let txtResponse = await prom;
	    let robj = JSON.parse(txtResponse);

	    if ('status' in robj && robj['status'] === 'error') {
		if ('reason' in robj) {
		    throw robj['reason'];
		} else {
		    throw "unknown server error";
		}
	    }
	    
	    $("#matchedfiles").parents('div.dataTables_wrapper').first().hide();
	    $("#static-result-container").hide();
	    
	    $("#project-info-container").hide();
	    $("#license-view-container").hide();
	    $("#cve-view-container").hide();
	    $("#component-view-container").hide();

	    $("#all-view-container").show();	
	    window.allviewtable.clear();

	    robj['view'].forEach((item, index) => {
		if (Object.keys(item).length > 0) {
		    updateProjectViewTable(item);
		}
	    });
	    
	    window.allviewtable.draw();
	    updateAllProjPieCharts(robj, '__all__');
	    
	    $("#all-project-view-label").html("All Projects");
	    
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	    return false;
	}
    }

    onComponentsView = async (e) => {
	window.onViewComponents('__all__');
    }
    
    onCVEsView = async (e) => {
	window.onViewCVEs('__all__');
    }
    
    onLicensesView = async (e) => {
	window.onViewLicenses('__all__');
    }
    onProtectTab = async (e) => {
	e.preventDefault();
	this.setState({tab: "protect"});
	return;
    }

    onDeepFoss = async (e) => {
	e.preventDefault();
	this.setState({tab: "main"});
	return;
    }

    // Login, Register, Signup Invite
    onShowRegisterButton = async (e) => {
	e.preventDefault();
	let isinvite = findGetParameter("invite");
	isinvite = isinvite && isinvite === 'true';
	if (isinvite) return;
	
	if ($("#signupbutton").css("display") === "none") {
	    $("#signupbutton").css("display", "block");
	    $("#register_link").html("Login");
	    $("#loginbutton").css("display", "none");
	    $("#confirmpassword_div").css("display", "block");
	} else {
	    $("#signupbutton").css("display", "none");
	    $("#register_link").html("Register");
	    $("#loginbutton").css("display", "block");
	    $("#confirmpassword_div").css("display", "none");
	}
    }
    
    onClearCode = async (e) => {
	setSrcEditorText('');
	// editor.getModel().setValue('');
    }
    
    onCodeSubmit = async (e) => {
	if (window.isBusy) {
	    // window.ShowMsg("Busy processing");
	    return;
	}
	
	let user = window.sessionStorage.getItem("cached_user");
	let token = window.sessionStorage.getItem("cached_token");
	let srcTxt = editor.getModel().getValue();
	let queryObj;
	let fileName;
	let isFileMode = OPModePrevSelected() === 'file';
	if (isFileMode) {
	    fileName = window.sessionStorage.getItem("cached_src_file");
	    snippetSearch = false;
	} else {
	    fileName = '';
	    snippetSearch = true;
	}

	let scope = "composition"; 
	let featureLevel = $('#df-feature-level').val();
	
	queryObj = JSON.stringify({"code": srcTxt, "fname": fileName, "user": user, "token": token, "scope": scope, "feature": featureLevel});

	if (false) { //scope === 'custom') {
	    let prom = runQuery("POST", "https://www.deepfoss.com/custom2/customcodesearch", queryObj, getFileWait, getFileDone);

	    try {
		let txtResponse = await prom;
		let tobj = JSON.parse(txtResponse);
		if ('scan_result' in tobj) {
   
		    let obj = tobj['scan_result'];
		    let fn = 'webservice_code.txt';
		    if (fileName !== '') {
			fn = fileName;
			window.sessionStorage.setItem(fileName, JSON.stringify(tobj));
		    } 

		    renderMatchFileTable(obj[fn]['full_match'],obj[fn]['snippet_match'], true);

		    setMatchEditorText('');
		    if (window.sDecorations) {
			editor.deltaDecorations(window.sDecorations, []);
		    }
		    MatchNavigator.HideButtons();

		    enableLinks();

		    let fnSrcInfoDisp = $('#src-info-display').html();
		    let fnLen = fnSrcInfoDisp.length;
		    if (fnSrcInfoDisp[fnLen-1] === '*') {
			fnSrcInfoDisp = fnSrcInfoDisp.substr(0, fnLen-1);
			$('#src-info-display').html(fnSrcInfoDisp);
		    }
		    $("#src-match-file").html("Custom DB scan results for <i>"+fnSrcInfoDisp+"</i>")
		} else {
		    if (('status' in tobj) && (tobj['status'] === 'error')) {
			ShowDiagnostics("Server Error", tobj['reason']);
		    } else {
			ShowDiagnostics("Server Error", 'scan_result not found in server response. '+JSON.stringify(tobj));
		    }
		}
		
	    } catch (err) {
		console.log(err);
		ShowDiagnostics("Server Error", JSON.stringify(err));
	
	    }
	    return;
	}

	if (isFileMode) {
	    queryObj = JSON.stringify({"fname": fileName, "user": user, "token": token, "scope": scope});
	}
	let prom = runQuery("POST", "https://www.deepfoss.com/local2/codesearchasync", queryObj, scanJobWait, scanJobDone);

	window.numSrcTextLines = srcTxt.split("\n").length;
	
	try {
	    let txtResponse = await prom;
	    let obj = JSON.parse(txtResponse);

	    if (obj['status'] === 'accept') {
		$('#minfo-display').show();
		let minfostr = '';
		if ('serverload' in obj) {
		    minfostr = color_format_text("&nbsp; [status:\""+obj['status']+"\"&nbsp;, server load: \""+obj['serverload']+"\"]&nbsp;"+obj['reason'], get_serverload_color(obj['serverload']));
		} else {
		    minfostr = "&nbsp;["+obj['status']+"]&nbsp;"+obj['reason'];
		}
		$('#minfo-display').html(minfostr);
		
		$("#scan-progress-bar")
		    .css("width", 0 + "%")
		    .attr("aria-valuenow", 0);
		
		$("#scan-progress-bar-disp").text(0 + "% done. Searching through 500 billion lines");
		$("#scan-progress").show();

		disableLinks();

		if (window.sessionStorage.getItem(fileName)) {
		    window.sessionStorage.removeItem(fileName);
		}
		// window.sessionStorage.setItem("cached_code_snippet", srcTxt);
		if (progressIntervalVar) {
		    clearInterval(progressIntervalVar);
		}
  		progressIntervalVar = setInterval(updateSearchStatus, progressBarUpdateTimeout);
		
	    } else if (obj['status'] === 'ready') {

		console.log("codesearchasync returns cached result");

		disp_cached_scan_result(obj, fileName, false);
		
		window.sessionStorage.setItem(fileName, JSON.stringify(obj));

		
	    } else { // error
		ShowDiagnostics("Server Error", obj['reason']);
		return;
	    }
	    
	} catch (err) {
	    console.log(err);
	    ShowDiagnostics("Server Error", JSON.stringify(err));
	}
    }
    
    loginRender() {
	LoginRender(this.state.signinstatus);
    }

    protectRender() {
	ProtectRender();
    }
    
    mainRender() {
	// console.log(this.state);
	// document.getElementById("app-info-div").style.visibility = "visible";
	if (window.sessionStorage.getItem("cached_user") && this.state.signedinuser === '') {
	    let cached_signedin_user = window.sessionStorage.getItem("cached_user");
	    let cached_token = window.sessionStorage.getItem("cached_token");
	    let cached_user_t = window.sessionStorage.getItem("cached_user_t");
	    // this.state.signedinuser = signedin_user;
	    this.setState({signedinuser: cached_signedin_user, token: cached_token, user_t: cached_user_t});
	}
	if (this.state.signedinuser === "") {
	    this.protectRender();
	    this.loginRender();
	    const useremail = findGetParameter("useremail");
	    if (useremail) {
		document.getElementById("loginuser").value = useremail;
	    }
	    const isinvite = findGetParameter("invite");
	    if (isinvite && isinvite === 'true') {
		if (this.state.signinstatus === '') {
		    $('#loginmessage').html("Create your password for login");
		}
		$("#signupbutton").show(); //css("display", "block");
		$('#signupbutton').html("Create Password");
		$('#loginbutton').css("display", "none");//hide();
		$("#register_link").css("display", "none");
		$("#confirmpassword_div").css("display", "block");
	    } else {
		$('#loginbutton').show();
		$("#confirmpassword_div").css("display", "none");
		$('#signupbutton').html("Register");
		$("#register_link").css("display", "block");
		$("#signupbutton").css("display", "none");
		$("#register_link").html("Register");
	    }
	    // document.getElementById("app-info-div").style.display = "none";
	} else {
	    console.log("logged in: "+this.state.user_t);
	    user_type = this.state.user_t;
	    
	    updateElementVisibility(true); //false to hide all project view user_type !== 'f');

	    $("#logout_link").show();
	    $("#logout_link").html("<u>"+this.state.signedinuser.replace('@deepfoss.com', '')+"</u>");
	    $("#login-div").hide();
	    $("#introduction-div").hide();
	    $("#login-msg-div").hide(); 
	    $("#code-container").show();
	    $("#dragBar").show();
	    //$("#mf-table").show();
	    $("#match-result-container").show();
	    $("#opMode").show();
	    $("#dark_mode").show();
	    $("#CustomKBOps").show();
	    $("#ViewControl").show();
	    
	    $("#scan-progress").hide();
	    $('#minfo-display').html("[matched file]");
	    
	    $("#staticreuslt").parents('div.dataTables_wrapper').first().hide();
	    $("#project-info-container").hide();
	    
	    $("#all-view-container").hide();
	    $("#df-feature-level").show();
	    
	    // $("#allprojectsview").parents('div.dataTables_wrapper').first().hide();

	    $("#component-view-container").hide();
	    $("#license-view-container").hide();
	    $("#cve-view-container").hide();
	    
	    let bom_url = "https://www.deepfoss.com/local2/bom?user=";
	    bom_url += this.state.signedinuser;
	    bom_url += "&token=";
	    bom_url += this.state.token;
	    bom_url += "&ts=0";
	    
	    $("#ExportAllViews")[0].href = bom_url;

	    if (document.cookie.search("filetreeoption") === -1){
		$("#filetreeoption").prop("checked", false);
	    } else {
		let filetree_option = findCookie("filetreeoption");
		if (filetree_option === "show_match_only") {
		    $("#filetreeoption").prop("checked", true);
		} else {
		    $("#filetreeoption").prop("checked", false);
		}
	    }

	    // OpenProjFcn(this.state.signedinuser, this.state.token);
	    OpenProjFcn(this.state.signedinuser, this.state.token).then(openstatus => {
		/*
		if (!openstatus) {
		    window.sessionStorage.removeItem("cached_user");
		    window.sessionStorage.removeItem("cached_token");
		    window.sessionStorage.removeItem("cached_user_t");
		    this.setState({signedinuser: '', signinstatus:  'invalid token',token: '', user_t: ''});
		}
		*/
	    });
	    
	    // $("#match-code-area").hide();
	    //$("#mf-table").show();
	    $("#busy-indicator").hide();
	    
	    if (document.cookie.search("opmode_type") === -1){
		document.cookie = "opmode_type=file";
		OPModeSwitch("file", user_type);
	    }
	    else{
		let cookie_opmode_type = findCookie("opmode_type");
		
		OPModeSwitch(String(cookie_opmode_type), user_type);
	    }

	    /*
	    if (document.cookie.search("theme_choice") === -1){
		document.cookie = "theme_choice=dark";
	    } else {
		let cookie_theme_choice = findCookie("theme_choice");
		ThemeSwitch(String(cookie_theme_choice));
	    }
	    */
	    if (document.cookie.search("theme_choice") === -1) {
		document.cookie = "theme_choice=dark";
		$('#dark_mode').html("<i class=\"fas fa-sun\"></i>");
		ThemeSwitch('dark');
	    } else {
		let cookie_theme_choice = findCookie("theme_choice");
		if (cookie_theme_choice === 'dark') {
		    $('#dark_mode').html("<i class=\"fas fa-sun\"></i>");
		    ThemeSwitch('dark');
		} else {
		    $('#dark_mode').html("<i class=\"fas fa-moon\"></i>");
		    ThemeSwitch('light');
		}
	    }
	    
	    MatchNavigator.HideButtons();
	    //if (window.sessionStorage.getItem("cached_full_match") &&
	    //window.sessionStorage.getItem("cached_snippet_match") &&
	    //window.sessionStorage.getItem("cached_code_snippet") &&
	    //window.sessionStorage.getItem("cached_src_file")) {
	    if (window.sessionStorage.getItem("cached_code_snippet") &&
		window.sessionStorage.getItem("cached_src_file")) {
		let fileName = window.sessionStorage.getItem("cached_src_file");
		let lang = getLangFromFileExt(fileName);

		setSrcEditorText(window.sessionStorage.getItem("cached_code_snippet"));
		
		// editor.getModel().setValue(window.sessionStorage.getItem("cached_code_snippet"));
		monaco.editor.setModelLanguage(editor.getModel(), lang);
		    
		// renderMatchFileTable(JSON.parse(window.sessionStorage.getItem("cached_full_match")), JSON.parse(window.sessionStorage.getItem("cached_snippet_match")),true);
		// $('#src-info-display').html(fileName+"*");
	    }
	    console.log("init_file_trees user_type: "+user_type);
	    init_file_trees([], user_type === 'p');
	    this.onAllView();
	    // $('#flist').jstree(true).refresh();
	}	
    }
    
    componentDidUpdate(prevProps,prevState) {
	if (this.state.tab === "protect") {
	    // this.protectRender();
	    // window.open("info.html");
	} else {
	    // $('#app-info-div').hide();
	    // document.getElementById("app-info-div").style.display = "none";
	    this.mainRender();
	}
    }
    
    componentDidMount() {
	// $("#protect_tab").click(this.onProtectTab);
	$("#deepfoss").click(this.onDeepFoss);
	//$("#index-custom-file").click(this.onIndex);
	$("#file-list-header").click(this.onSubmitProject);
	$("#CustomKBIndex").click(this.onIndex);
	$("#CustomKBList").click(this.onListPKB);

	$("#AllProjects").click(this.onAllView);
	$("#Components").click(this.onComponentsView);
	$("#Licenses").click(this.onLicensesView);
	$("#Vulnerabilities").click(this.onCVEsView);
	// $("#ExportAllViews").click(this.onExportAllViews);
	$("#CheckComponent").click(this.onCheckComponent);
	$("#search-component-cve").click(this.onSearchComponentCVE);
	$('#component-path').keypress(this.onComponentPathEnter);
	$('#rescan-project').click(this.onClickRescanProjectButton);
	
	$("#loginbutton").click(this.onSignIn);
	$("#signupbutton").click(this.onSignUp);
	$("#register_link").click(this.onShowRegisterButton);
	
	$("#logout_action").click(this.onSignOut);
	$("#resetpassword_action").click(window.onResetPassword);
	$("#toggle-current-password").click(window.onTogglePassword);
	$("#toggle-new-password").click(window.onTogglePassword);
	$("#toggle-verify-new-password").click(window.onTogglePassword);
	
	$("#dark_mode").click(this.onDarkModeSwitch);
	$("#reset-password-submit").click(this.onResetPassword);
	$("#code-submit").click(this.onCodeSubmit);
	$("#clear-code").click(this.onClearCode);
	$("#pkb-sel-all").click(this.onSelAllPKB);
	$("#delete-pkb-repos").click(this.onDeletePKB);
	$("#filetreeoption").click(this.onShowFileTreeOption);
	
	const tree_data = [
	    { "text" : "Root node", "children" : [
		{ "text" : "Child node 1", "children": [{"text": "cc1"}] },
		{ "text" : "Child node 2" }
	    ]
	    },
	    { "text" : "Root node2", "children" : [
		{ "text" : "Child2 node 1", "children": [{"text": "cc2"}] },
		{ "text" : "Child2 node 2" }
	    ]
	    }
	];

	window.staticresulttable = $("#staticresult").DataTable(
	    {"scrollY": "300px", "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
	     "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $('td', nRow).css({'background-color': dtRowColor, 'color': frontColor});
             },
	     /*
	     "rowCallback": function(row, data, index) {
		 // Add row numbers to the first column
		 $('td:eq(0)', row).html(index + 1);
		 $('td', row).css({'background-color': dtRowColor});
             },*/
	     "pageLength": 20,
	     "order": [[2, 'desc'], [0, "asc"]],
	     columns: [
		 {width: "10%"},
		 {width: "25%"},
		 {width: "10%"},
		 {width: "55%"}
             ],
	     select: {
                style: 'os',
                className: 'focusedRow',
                selector: 'td:last-child a'
            },
	     "language" : {
		 "emptyTable": "No issues found",
		 "search":"Static Analysis&nbsp;&nbsp;&nbsp;&nbsp; <button id=\"staticExportToExcel\"> Export </button>&nbsp;&nbsp;  Search:",
		 "lengthMenu": "Show _MENU_ entries",
		 "info": "Showing _START_ to _END_ of _TOTAL_ entries"
	     },
	     "autoWidth": false,
	     "fixedColumns": true,
	     "paging": true
	    });

	window.dtable = $("#matchedfiles").DataTable(
	    {"scrollY": "300px", "lengthMenu": [[3, 20, 50, 100, -1], [3, 20, 50, 100, "All"]],
	     "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $('td', nRow).css({'background-color': dtRowColor});
             },
	     "pageLength": 3,

	     "order": [[2, 'asc'], [3, "desc"], [6, "desc"]],
	     columns: [
		 {width: "10%"},
		 { width: "15%"},
		 {width: "8%"},
		 {width: "8%"},
		 {width: "8%"},
		 {width: "8%"}, // version
		 {width: "8%"}, // license
		 {width: "8%"}, // publish
		 { width: "10%"},
		 { width: "10%"}, // homepage
		 {width: "8%"}, // download
		 { width: "1%"}
             ],
	     select: {
                style: 'os',
                className: 'focusedRow',
                selector: 'td:last-child a'
            },
	     /*
	       columnDefs: [
	       { width: "20%", targets: 0 },
	       { width: "30%", targets: 1 },
	       { width: "3%", targets: 2 },
	       { width: "2%", targets: 3 },
	       { width: "5%", targets: 4 },
	       { width: "5%", targets: 5 },
	       { width: "5%", targets: 6 },	
	       { width: "20%", targets: 7 },
	       { width: "5%", targets: 8 },
	       { width: "5%", targets: 9 }
               ],
	     */
	     "language" : {
		 "emptyTable": "No matching results found",
		 "search":"Search:",
		 "lengthMenu": "Show _MENU_ entries",
		 "info": "Showing _START_ to _END_ of _TOTAL_ entries"
	     },
	     "autoWidth": false,
	     "fixedColumns": true,
	     "paging": true,
	     dom: 'l<"toolbar">frtip',
	     initComplete: function(){
		 $("div.toolbar").html('&nbsp&nbsp<button type="button" id="exportToExcel">Export</button>&nbsp&nbsp <label id="src-match-file"></label>');  } 
	     //"paging": false,
	     //"searching": false
	     /*
	       dom: '<"toolbar">frtip',
	       fnInitComplete: function(){
	       $('div.toolbar').html('<button>export</button>');
               }
	     */
	    });
	/*
	window.dtable.on('order.dt search.dt', function () {
            let i = 1;
 
            window.dtable.cells(null, 0, { search: 'applied', order: 'applied' }).every(function (cell) {
		this.data(i++);
            });
	}).draw();
	*/
	window.allviewtable = $("#allprojectsview").DataTable(
	    {"scrollY": "300px", "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
	     "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $('td', nRow).css({'background-color': dtRowColor});
             },
	     columns: [
		 {width: "10%"},
		 {width: "10%"},
		 {width: "10%"},
		 {width: "10%"},
		 {width: "10%"},
		 {width: "10%"},
		 {width: "10%"},
		 {width: "10%"},
		 {width: "10%"},
		 {width: "10%"}
             ],
	     select: {
                style: 'os',
                className: 'focusedRow',
                selector: 'td:last-child a'
             },
	     "language" : {
		 "emptyTable": "No items",
		 "search":     "<button id=\"projectExportToExcel\"> Export </button>&nbsp;&nbsp;  Search:",
		 "lengthMenu": "<label id=\"all-project-view-label\"></label> Show _MENU_ entries",
		 "info": "Showing _START_ to _END_ of _TOTAL_ entries"
	     },
	     "autoWidth": false,
	     "fixedColumns": true,
	     "paging": true
	    });
	
	window.componentviewtable = $("#componentview").DataTable(
	    {"scrollY": "300px", "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
	     "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $('td', nRow).css({'background-color': dtRowColor});
             },
	     columns: [
		 {width: "15%"}, // name
		 {width: "5%"},  // star
		 {width: "15%"}, // versions
		 {width: "10%"}, // license,
		 {width: "10%"},  // license compliance
		 {width: "15%"}, // cve
		 {width: "5%"},  // num files impacted
		 {width: "5%"},  // num projects impacted
		 {width: "15%"}, // homepage
		 {width: "5%"}   // source
             ],
	     select: {
                style: 'os',
                className: 'focusedRow',
                selector: 'td:last-child a'
            },
	     "language" : {
		 "emptyTable": "No items",
		 "search":     "<button id=\"componentExportToExcel\"> Export </button>&nbsp;&nbsp;  Search:",
		 "lengthMenu": "<label id=\"components-view-label\"></label> Show _MENU_ entries",
		 "info": "Showing _START_ to _END_ of _TOTAL_ entries"
	     },
	     "autoWidth": false,
	     "fixedColumns": true,
	     "paging": true 
	    });
	
	window.dependencyviewtable = $("#dependencyview").DataTable(
	    {"scrollY": "300px", "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
	     "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $('td', nRow).css({'background-color': dtRowColor});
             },
	     columns: [
		 {width: "15%"}, // package URL
		 {width: "15%"},  // project package manifest
		 {width: "15%"}, // CVE
		 {width: "50%"}, // vulnerability
		 {width: "5%"}  // provenance
             ],
	     select: {
                style: 'os',
                className: 'focusedRow',
                selector: 'td:last-child a'
            },
	     "language" : {
		 "emptyTable": "No items",
		 "search":     "<button id=\"dependencyExportToExcel\"> Export </button>&nbsp;&nbsp;  Search:",
		 "lengthMenu": "<label id=\"dependency-view-label\"></label> Show _MENU_ entries",
		 "info": "Showing _START_ to _END_ of _TOTAL_ entries"
	     },
	     "autoWidth": false,
	     "fixedColumns": true,
	     "paging": true 
	    });
			
	window.cveviewtable = $("#cveview").DataTable(
	    {"scrollY": "300px", "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
	     "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $('td', nRow).css({'background-color': dtRowColor});
             },
	     columns: [
		 {width: "30%"},  // CVE
		 {width: "10%"},  // severity
		 {width: "20%"},  // num files impacted
		 {width: "20%"},  // num projects impacted
		 {width: "20%"},  // impact type
             ],
	     select: {
                style: 'os',
                className: 'focusedRow',
                selector: 'td:last-child a'
            },
	     "language" : {
		 "emptyTable": "No items",
		 "search":     "Vulnerability Match&nbsp;&nbsp;&nbsp;&nbsp; <button id=\"cveExportToExcel\"> Export </button>&nbsp;&nbsp;  Search:",
		 "lengthMenu": "<label id=\"cve-view-label\"></label> Show _MENU_ entries",
		 "info": "Showing _START_ to _END_ of _TOTAL_ entries"
	     },
	     "autoWidth": false,
	     "fixedColumns": true,
	     "paging": true
	    });
	
	window.vulviewtable = $("#vulview").DataTable(
	    {"scrollY": "300px", "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
	     "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $('td', nRow).css({'background-color': dtRowColor});
             },
	     columns: [
		 {width: "15%"},  // file path
		 {width: "15%"},  // cve_id
		 {width: "15%"},  // repos
		 {width: "25%"},  // commit
		 {width: "15%"},  // match file
		 {width: "15%"},  // fix file
             ],
	     select: {
                style: 'os',
                className: 'focusedRow',
                selector: 'td:last-child a'
            },
	     "language" : {
		 "emptyTable": "No items",
		 "search":     "Vulnerability Snippet Match&nbsp;&nbsp;&nbsp;&nbsp; <button id=\"vulExportToExcel\"> Export </button>&nbsp;&nbsp;  Search:",
		 "lengthMenu": "<label id=\"vul-view-label\"></label> Show _MENU_ entries",
		 "info": "Showing _START_ to _END_ of _TOTAL_ entries"
	     },
	     "autoWidth": false,
	     "fixedColumns": true,
	     "paging": true
	    });

	window.licenseviewtable = $("#licenseview").DataTable(
	    {"scrollY": "300px", "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
	     "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $('td', nRow).css({'background-color': dtRowColor});
             },
	     columns: [
		 {width: "20%"}, // license
		 {width: "20%"}, // license compliance
		 {width: "40%"},  // num files impacted
		 {width: "40%"},  // num projects impacted
             ],
	     select: {
                style: 'os',
                className: 'focusedRow',
                selector: 'td:last-child a'
            },
	     "language" : {
		 "emptyTable": "No items",
		 "search":     "View Licenses&nbsp;&nbsp;&nbsp;&nbsp; <button id=\"licenseExportToExcel\"> Export </button>&nbsp;&nbsp;  Search:",
		 "lengthMenu": "<label id=\"licenses-view-label\"></label> Show _MENU_ entries",
		 "info": "Showing _START_ to _END_ of _TOTAL_ entries"
	     },
	     "autoWidth": false,
	     "fixedColumns": true,
	     "paging": true
	    });
	this.mainRender();
	
    }
    
    render() {
	return (
		<div> </div>
	);
    }
}

export default App;
